import { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Modal, DateField, AntdSelect, FormSelectAntd, FormInputAntd, AntdButton } from "components"
import { equipmentsValidationSchema } from "Schema"
import { EmployeeEquipmentIssuedInterface, GetEmployeeEquipmentIssuedInterface } from "@type/employees.types"
import useAppSelector from "hooks/useAppSelector"
import { selectCustomerOptions } from "store/Customers/customers.slice"
import { Typography } from "antd"

export enum EquipmentType {
	CAP = "Cap",
	COAT = "Coat",
	WAIST = "Waist",
	CHEST = "Chest",
	INSIDE_LEG = "Inside Leg",
	DRESS = "Dress",
	COLLAR = "Collar",
	SHOES = "Shoes",
	GLOVES = "Gloves",
	UNIFORM = "Uniform",
}

const EquipmentTypeArray = [
	{ name: "Hard Hat", value: EquipmentType.CAP },
	{ name: "Gloves", value: EquipmentType.GLOVES },
	{ name: "High Visibility Jacket", value: EquipmentType.COAT },
	// { name: "Waist", value: EquipmentType.WAIST },
	// { name: "Inside Leg", value: EquipmentType.INSIDE_LEG },
	{ name: "Dress", value: EquipmentType.DRESS },
	// { name: "Collar", value: EquipmentType.COLLAR },
	{ name: "Shoes", value: EquipmentType.SHOES },
	{ name: "Uniform", value: EquipmentType.UNIFORM },
]

interface Props {
	open: boolean
	issuedData?: GetEmployeeEquipmentIssuedInterface | null
	modalHandler: (open: boolean) => void
	IsSaving?: boolean
	onSubmit: (data: EmployeeEquipmentIssuedInterface) => void
}

const IssuedModal: React.FC<Props> = ({ open, issuedData = null, modalHandler, IsSaving = false, onSubmit }) => {
	const methods = useForm<EmployeeEquipmentIssuedInterface>({
		resolver: yupResolver(equipmentsValidationSchema),
	})
	const customersOptions = useAppSelector(selectCustomerOptions)
	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = methods
	useEffect(() => {
		if (issuedData) {
			reset({
				...issuedData,
				customer: issuedData.customer._id,
				issueDate: issuedData?.issueDate,
				returnDate: issuedData?.returnDate,
				checked: issuedData?.checked,
				nextCheck: issuedData?.nextCheck,
			})
		} else reset({})
	}, [issuedData?._id])

	return (
		<Modal
			open={open}
			title={
				<div className="flex w-full items-center justify-center">
					<Typography.Title level={5}> Additional Details</Typography.Title>
				</div>
			}
			modalHandler={modalHandler}
		>
			<div className="justify-center sm:flex sm:items-start">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-y-5">
							<div className="grid grid-cols-3 gap-y-2 gap-x-6">
								<FormSelectAntd
									disabled={!EquipmentTypeArray.length}
									label="Equipment"
									name="equipmentType"
									options={EquipmentTypeArray.map(type => ({
										label: type.name,
										value: type.value,
									}))}
								/>
								<FormInputAntd type="text" label="Quantity" name="quantity" />

								<FormInputAntd type="text" name="serialNumber" label="Serial Number" />
								<DateField
									label="Issued Date"
									setValue={setValue}
									errors={errors}
									watch={watch}
									name={"issueDate"}
								/>
								<DateField
									label="Return Date"
									setValue={setValue}
									watch={watch}
									errors={errors}
									name="returnDate"
								/>
								<DateField
									label="Checked"
									setValue={setValue}
									watch={watch}
									errors={errors}
									name="checked"
								/>
								<DateField
									label="Next Check"
									setValue={setValue}
									watch={watch}
									errors={errors}
									name="nextCheck"
								/>
								<FormInputAntd name="notes" label="Notes" type="text" />
								<FormInputAntd name="audit" label="Audit" type="text" />
								<AntdSelect
									disabled={!customersOptions.length}
									label="Customer"
									name="customer"
									size="middle"
									formValueSetter={setValue}
									selected={watch("customer") ?? ""}
									className="h-auto"
									isControlled={true}
									options={customersOptions}
									errors={errors}
								/>
							</div>
							<div className="align-center flex justify-center">
								<AntdButton loading={IsSaving} disabled={!isDirty} className="w-min" htmlType="submit">
									Save
								</AntdButton>
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
		</Modal>
	)
}

export default IssuedModal
