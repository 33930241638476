import axios from "axios"
import { uploadFileInterface } from "./type"

const ROOT_PATH = "media"

const newInstance = axios.create()
export default class GenericService {
	static upload = (files: File[] | File) => {
		const formData = new FormData()
		if (Array.isArray(files)) files.forEach(file => formData.append("file", file))
		else formData.append("file", files as File)
		return axios.post<uploadFileInterface>(`${ROOT_PATH}/upload/multiple`, formData)
	}
	static getSignedUrl = (path: string) => {
		return axios.post(`${ROOT_PATH}/url`, { path })
	}

	static getBlobResponse = (url: string) =>
		newInstance.get(url, {
			responseType: "blob",
			headers: {},
		})
}
