// PACKAGES/ HOOKS
import React, { useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
// COMPONENTS
import {
	AntdSelect1,
	AntdButton,
	FormCheckbox,
	FormInputAntd,
	FormSelectAntd,
	// Icon,
	TimeField,
} from "components"
// SCHEMA/TYPES
import { postValidationSchema, postInitialSchema } from "Schema"
import { CreatePostsInterface } from "@type/locations.types"
import { useAppDispatch, useAppSelector } from "hooks"
import {
	selectLocationContractsData,
	getLocationContractsByLocationId,
	isLocationContractsLoading,
} from "store/Location/locationContracts.slice"
import {
	clearPostList,
	getLocationPostsByContractId,
	isLocationPostsLoading,
	isLocationPostsSaving,
	saveLocationPosts,
	selectLocationPostsData,
	selectPostsById,
} from "store/Location/locationPosts.slice"
// UTILS
import {
	convertIOSDateToHTMLFormat,
	// getTimeInUtc
} from "config/utils"
import { getPBCodes, selectPBCodeDropdownList } from "store/PayBill/PBCodes.slice"
import { EmployeeTypesEnum, Ongoing, PBCodeOptions, Permissions, employeeTypeOptions } from "config/constants"
import { AllowedTo } from "react-abac"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedLocation } from "store/globalState.slice"

export enum CliBehaviors {
	ACCEPT = "Accept",
	REJECT = "Reject",
	ACCEPT_BUT_FLAG = "Accept but flag",
}

// const CliBehaviorsList = [
// 	{ label: CliBehaviors.ACCEPT, value: CliBehaviors.ACCEPT },
// 	{ label: CliBehaviors.REJECT, value: CliBehaviors.REJECT },
// 	{ label: CliBehaviors.ACCEPT_BUT_FLAG, value: CliBehaviors.ACCEPT_BUT_FLAG },
// ]
const Posts: React.FC = () => {
	const dispatch = useAppDispatch()

	const selected = useAppSelector(selectSelectedLocation)

	// const [contractId, setContractId] = useState<string>("")
	const [postsId, setPostsId] = useState<string>("")

	const contracts = useAppSelector(selectLocationContractsData()) || null
	const isContractLoading = useAppSelector(isLocationContractsLoading())

	const posts = useAppSelector(selectLocationPostsData()) || []
	const PostData = useAppSelector(selectPostsById(postsId)) || null
	const isPostSaving = useAppSelector(isLocationPostsSaving())
	const isPostLoading = useAppSelector(isLocationPostsLoading())

	const PBCodesList = useAppSelector(selectPBCodeDropdownList)
	const methods = useForm<CreatePostsInterface>({ resolver: yupResolver(postValidationSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isDirty, isSubmitting },
	} = methods

	useEffect(() => {
		if (selected) {
			reset({ ...postInitialSchema })
			dispatch(getLocationContractsByLocationId(selected))
		}
	}, [selected])

	useEffect(() => {
		dispatch(getPBCodes(PBCodeOptions.POST))
	}, [dispatch])

	useEffect(() => {
		if (PostData) reset(PostData)
	}, [PostData])

	useEffect(() => {
		if (posts?.length == 0) reset({ ...postInitialSchema, contract: watch("contract") })
	}, [posts.length])

	useEffect(() => {
		if (contracts?.length) {
			// setContractId(contracts[0]._id)
			setValue("contract", contracts[0]._id)
			dispatch(getLocationPostsByContractId(contracts[0]._id))
		}
	}, [contracts])

	const onSubmit: SubmitHandler<CreatePostsInterface> = data => {
		dispatch(saveLocationPosts({ ...data }, postId => setPostsId(postId)))
	}
	const contractOptions = useMemo(() => {
		if (contracts?.length)
			return [
				{ label: "Select Contract", value: "" },
				...(contracts || []).map(({ startDate, finishDate, _id }) => ({
					label: finishDate
						? `${convertIOSDateToHTMLFormat(startDate)}-${convertIOSDateToHTMLFormat(finishDate)}`
						: `${convertIOSDateToHTMLFormat(startDate)}- ${Ongoing}`,
					value: _id,
				})),
			]
	}, [contracts])
	return (
		<div className="flex flex-col pb-2.5">
			<div className="mt-2 ml-2 h-4">
				<AuditTrail id={posts[0]?._id} name={Entities.LOCATION_POSTS} />
			</div>
			<FormProvider {...methods}>
				<form className="w-1/2 pt-3" onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-2 gap-y-3 gap-x-6">
						<AntdSelect1
							loading={isContractLoading}
							onChange={e => {
								// setContractId(e)
								setValue("contract", e, { shouldDirty: true })
								setPostsId("")

								if (e === "") {
									reset(postInitialSchema)
									dispatch(clearPostList())
								} else dispatch(getLocationPostsByContractId(e))
							}}
							label="Contracts"
							value={watch("contract")}
							options={contractOptions}
						/>

						<AntdSelect1
							loading={isPostLoading}
							onChange={e => {
								setPostsId(e)
								if (e === "") {
									reset({ ...postInitialSchema, contract: watch("contract") })
								}
							}}
							label="Posts"
							placeholder="Create Post"
							value={postsId as string}
							options={[{ name: "Create Post", _id: "" }, ...posts]?.map(({ name, _id }) => ({
								label: name,
								value: _id,
							}))}
						/>

						<AllowedTo perform={Permissions.VIEW_POST_PAY_RATES}>
							<FormInputAntd type="text" label="Client Charge Rate" name="clientChargeRate" />
							{/* <Icon name="pound" size={20} color="secondary-dark" className="absolute top-1/2 left-[18rem]" /> */}
							<FormInputAntd type="text" label="Officer Pay Rate" name="officerPayRate" />
							{/* <Icon
							name="pound"
							size={20}
							color="secondary-dark"
							className="absolute left-[40.5rem] top-1/2"
						/> */}
						</AllowedTo>

						<FormInputAntd type="text" label="Short Name" name="shortName" />
						<FormInputAntd type="text" label="Name" name="name" />
						<TimeField label="Start Time" watch={watch} formValueSetter={setValue} name="startTime" />
						<TimeField label="Finish Time" watch={watch} formValueSetter={setValue} name="finishTime" />
						<FormSelectAntd
							disabled={!employeeTypeOptions.length}
							label="Type"
							name="type"
							defaultValue={EmployeeTypesEnum.SECURITY}
							options={employeeTypeOptions}
						/>
						<FormInputAntd type="number" label="Display Order" name="displayOrder" />
						{/* <FormInput
							containerClass="mt-2.75 !flex-row-reverse justify-end"
							className="mr-2 !w-auto"
							register={register}
							
							labelClass="text-left"
							type="checkbox"
							label="Used For Jobs"
							name="usedForJobs"
						/> */}
						<div className="flex flex-col">
							<FormCheckbox label="Site Training Required" name="siteTrainingRequired" />
							<FormCheckbox label="Geo Fencing Enabled" name="geoFencingEnabled" />
						</div>
						{/* <FormInput
							containerClass="mt-2.75 !flex-row-reverse justify-end"
							className="mr-2 !w-auto"
							register={register}
							
							labelClass="text-left"
							type="checkbox"
							label="Enable"
							name="enabled"
						/> */}
						<FormSelectAntd
							label="PB Code"
							name="PBCode"
							disabled={!PBCodesList?.length}
							options={PBCodesList}
						/>
						<FormInputAntd type="text" label="Telephone 1" name="telephone1" />
						<FormInputAntd type="text" label="Telephone 2" name="telephone2" />
						<FormInputAntd type="text" label="Telephone 3" name="telephone3" />
						{/* <FormInput
							containerClass="mr-5.5 max-w-84 w-full"
							className="!w-auto"
							register={register}
							
							label="Report Order"
							name="reportCode"
						/> */}
						{/* {CliBehaviorsList.map(({ label, value }, i) => (
							<FormInput
								key={`${value}${i}`}
								containerClass="mt-2.75 !flex-row-reverse justify-end"
								className="mr-2 !w-auto"
								register={register}
								
								labelClass="order-1 ml-5.5"
								type="radio"
								value={value}
								label={label}
								id={value}
								name="cliBehavior"
							/>
						))} */}

						<FormInputAntd type="text" label="Full Address" name="fullAddress" />
						<FormInputAntd type="number" label="Radius" name="radius" />
						<FormInputAntd type="number" label="Latitude" name="latitude" />
						<FormInputAntd type="number" label="Longitude" name="longitude" />
						<FormInputAntd
							type="textarea"
							label="Location And Instructions"
							name="locationAndInstructions"
						/>
					</div>
					<AllowedTo perform={Permissions.UPDATE_LOCATION_POSTS}>
						<div className="flex gap-3 py-2">
							<AntdButton loading={isPostSaving} disabled={!isDirty || isSubmitting} htmlType="submit">
								{PostData ? "Update" : "Save"}
							</AntdButton>
							<AntdButton
								onClick={e => {
									e.preventDefault()
									reset({ ...postInitialSchema })
									setPostsId("")
								}}
								htmlType="button"
							>
								Clear
							</AntdButton>
						</div>
					</AllowedTo>
				</form>
			</FormProvider>
		</div>
	)
}

export default Posts
