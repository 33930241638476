import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"

import { AntdButton, Table2 } from "components"
import IssuedModal from "./IssuedModal"

import {
	getEmployeesIssuedByEmployeeId,
	selectEmployeeIssuedData,
	selectEmployeeIssuedSaving,
	saveEmployeeIssuedData,
} from "store/Employee/equipmentIssued.slice"
import { EmployeeEquipmentIssuedInterface, GetEmployeeEquipmentIssuedInterface } from "@type/employees.types"
import { Utils } from "config"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import { Entities } from "types/auditTrail.types"
import AuditTrail from "components/AuditTrail"
import { SelectedPropInterface } from "services/generic/type"
import { getCustomers } from "store/Customers/customers.slice"
import { PlusOutlined } from "@ant-design/icons"
import { Divider, Typography } from "antd"
const { convertIOSDateToHTMLFormat } = Utils

export enum EquipmentType {
	CAP = "Cap",
	COAT = "Coat",
	WAIST = "Waist",
	CHEST = "Chest",
	INSIDE_LEG = "Inside Leg",
	DRESS = "Dress",
	COLLAR = "Collar",
	SHOES = "Shoes",
	GLOVES = "Gloves",
}

const Issued: React.FC<SelectedPropInterface> = ({ selected }) => {
	const dispatch = useAppDispatch()
	const IssuedEquipment = useAppSelector(selectEmployeeIssuedData())
	const isSavingIssuedEquipment = useAppSelector(selectEmployeeIssuedSaving())

	const [issuedData, setIssuedData] = useState<GetEmployeeEquipmentIssuedInterface | null>(null)
	const [modalOpen, setModalOpen] = useState(false)

	useEffect(() => {
		dispatch(getCustomers())
	}, [])

	useEffect(() => {
		selected && dispatch(getEmployeesIssuedByEmployeeId(selected))
	}, [selected])

	const onSubmit = (data: EmployeeEquipmentIssuedInterface) =>
		dispatch(
			saveEmployeeIssuedData({ ...issuedData, ...data, employee: selected }, () => {
				setModalOpen(false)
				setIssuedData(null)
			}),
		)
	return (
		<div className="flex flex-col gap-y-3">
			{/* <div className="mb-8 flex items-end space-x-5 border-b-[0.75px] border-secondary-dark py-2"> */}

			<div className="flex w-full items-center gap-x-2 p-2">
				<div className="w-full">
					<Divider orientation="left" plain>
						<Typography.Title level={5}>Issued Equipments</Typography.Title>
					</Divider>
				</div>
				<AntdButton
					className="flex w-min items-center justify-center"
					onClick={() => setModalOpen(true)}
					icon={<PlusOutlined />}
				>
					Add New
				</AntdButton>
			</div>
			{/* <div className="text-xl font-semibold">Issued Equipments</div> */}
			{/* <div> */}
			{/* <AntdButton onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
					Add New
				</AntdButton> */}
			{/* <Button
						className="flex-row-reverse !py-[5px] px-3 "
						iconProps={{ name: "add", size: 15, className: "mr-2" }}
					>
						Add New
					</Button> */}
			{/* </div> */}
			{/* </div> */}

			<Table2
				columns={[
					{ label: "Equipments", key: "equipmentType" },
					{ label: "Quantity", key: "quantity" },
					{
						label: "Issued Date",
						key: "issueDate",
						render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{
						label: "Return Date",
						key: "returnDate",
						render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{
						label: "Checked",
						key: "checked",
						render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{
						label: "Next Checked",
						key: "nextCheck",
						render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{ label: "Notes", key: "notes" },
					{ label: "Audits", key: "audit" },
					{
						label: "Customer",
						key: "customer",
						render: ({ row, key }) => <div className="ml-4 text-left">{row[key]?.name}</div>,
					},
					{
						label: "History",
						key: "_id",
						render: ({ row }) => (
							<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_EQUIPMENTS_ISSUED} showLabel={false} />
						),
					},
					{
						label: "Actions",
						key: "action",
						render: data => {
							return (
								<AntdButton
									onClick={() => {
										setModalOpen(true)
										setIssuedData(data.row)
									}}
								>
									Update
								</AntdButton>
							)
						},
					},
				]}
				rows={IssuedEquipment}
			/>
			<IssuedModal
				open={modalOpen}
				issuedData={issuedData}
				IsSaving={isSavingIssuedEquipment}
				modalHandler={(close: boolean) => {
					setModalOpen(close)
					setIssuedData(null)
				}}
				onSubmit={onSubmit}
			/>
		</div>
	)
}

export default Issued
