// import logo from "../../images/logo1.jpg"
import { useForm } from "react-hook-form"
import { Button, DateField, FormInput, FormInputPassword, FormSelect, FormTextArea, Section } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { jafValidationSchema } from "Schema/jaf/jafSchema"
import { useEffect, useState } from "react"
import useAppDispatch from "hooks/useAppDispatch"
import { approveJaf, createJaf, isJafHandling, isJafSaving } from "store/Jaf/jaf.slice"
import { getBranches, selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { getSDSuppliers, selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import useAppSelector from "hooks/useAppSelector"
import { getSDCounties, selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"

const LivingPlace = [
	{ label: "House", value: "house" },
	{ label: "flat", value: "flat" },
]

const LivingAs = [
	{ label: "Owner", value: "owner" },
	{ label: "renting", value: "renting" },
	{ label: "living with parents", value: "livingWithParents" },
	{ label: "lodging", value: "lodging" },
]

const convicted = [
	{ label: "yes", value: "yes" },
	{ label: "no", value: "no" },
]

interface Props {
	data?: any
	disableSubmit?: boolean
}

const JavForm: React.FC<Props> = ({ data = {}, disableSubmit = false }) => {
	const {
		register,
		setValue,
		watch,
		handleSubmit,
		reset,
		getValues,
		formState: { errors },
	} = useForm<any>({
		mode: "onChange",
		resolver: yupResolver(jafValidationSchema),
	})
	const dispatch = useAppDispatch()
	const suppliers = useAppSelector(selectSDSuppliersDropdownList)
	const branches = useAppSelector(selectBranchesMappedAsOptions())
	const saving = useAppSelector(isJafSaving())
	const handling = useAppSelector(isJafHandling())
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []
	const [error, setError] = useState({ value: false, password: false })

	const submitHandler = async (data: any) => {
		dispatch(createJaf(data))
	}

	useEffect(() => {
		if (Object.entries(data).length) reset(data)
		if (!data.approved) {
			setValue("supplier", "")
			setValue("branch", "")
			setValue("password", "")
			setValue("confirmPassword", "")
		}
	}, [data])

	useEffect(() => {
		dispatch(getSDCounties())
	}, [])

	useEffect(() => {
		if (disableSubmit) {
			dispatch(getBranches(false))
			dispatch(getSDSuppliers(false))
		}
	}, [dispatch, disableSubmit])

	const handleApprove = (details: any) => {
		const { id, branch, supplier, password, confirmPassword, ...rest } = details
		if ((!branch && !supplier) || !password || !confirmPassword) {
			if (!branch && !supplier) {
				if (!password || !confirmPassword) setError({ value: true, password: true })
				else setError(err => ({ ...err, value: true }))
			} else setError(err => ({ ...err, password: true }))
		} else {
			setError({ password: false, value: false })
			dispatch(approveJaf(id, { ...rest, password, ...(branch ? { branch } : { supplier }) }))
		}
	}

	return (
		<Section className="flex justify-center   ">
			<div className="container mx-auto my-8  px-5">
				<div className="item-center mx-auto  flex flex-row text-center">
					{/* <img src={logo} alt="TimeTrail-Login" className="h-36 w-auto object-cover" /> */}
					<div className="flex w-full items-center justify-center">
						<h1 className="mb-8 mr-24 text-10 font-extrabold">Job application Form</h1>
					</div>
				</div>

				{/* Form start */}
				<div className="m-5 border-[1px] border-gray-400 ">
					<form onSubmit={handleSubmit(submitHandler)}>
						<div>
							{/* Personal info  */}

							<div className="p-5">
								<h1 className="text-4">Personal Information</h1>
								<div className="grid grid-cols-2 gap-y-2">
									<FormSelect
										containerClass="max-w-84 "
										className="mr-5.5 mt-1.25 w-auto "
										label="Surname: *"
										name="surname"
										errors={errors}
										register={register}
										options={[
											{ label: "Select Surname", value: "" },
											{ label: "Mr", value: "mr" },
											{ label: "Mrs", value: "mrs" },
											{ label: "Miss", value: "miss" },
											{ label: "Ms", value: "ms" },
										]}
									/>
									<FormInput
										containerClass="max-w-84 "
										className="mr-5.5 mt-1.25 w-auto "
										register={register}
										errors={errors}
										type="text"
										label="Maiden/Former Names: *"
										name="maidenOrFormerNames"
									/>
									<FormInput
										containerClass="max-w-84 "
										className="mr-5.5 mt-1.25 w-auto "
										register={register}
										errors={errors}
										type="text"
										label="Forenames: *"
										name="forenames"
									/>
									<FormInput
										containerClass="max-w-84 "
										className="mr-5.5 mt-1.25 w-auto "
										register={register}
										errors={errors}
										type="email"
										label="Email Address:"
										name="email"
									/>
									<FormTextArea
										containerClass="mr-5.5 max-w-84 "
										className="!w-auto"
										register={register}
										errors={errors}
										label="Address: *"
										name="address"
									/>
									<div className="">
										<FormInput
											containerClass="max-w-84 "
											className="mr-5.5 mt-1.25 w-auto "
											register={register}
											errors={errors}
											label="How long have you lived at your present address?"
											name="presentAddressDuration"
										/>
										<div className="flex space-x-20 ">
											<div>
												{LivingPlace.map(({ label, value }, i) => (
													<FormInput
														key={`${value}${i}`}
														containerClass="mt-2.75 !flex-row-reverse justify-end"
														className="mr-2 !w-auto"
														register={register}
														errors={errors}
														labelClass="order-1 ml-5.5"
														type="radio"
														value={value}
														label={label}
														id={value}
														name="livingPlace"
													/>
												))}
											</div>
											<div>
												{LivingAs.map(({ label, value }, i) => (
													<FormInput
														key={`${value}${i}`}
														containerClass="mt-2.75 !flex-row-reverse justify-end"
														className="mr-2 !w-auto"
														register={register}
														errors={errors}
														labelClass="order-1 ml-5.5"
														type="radio"
														value={value}
														label={label}
														id={value}
														name="livingAs"
													/>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Personal info finish */}
							<hr className="my-5" />
							{/* Contact info */}
							<div className="p-5">
								<h1 className="text-4">Contact Information</h1>
								<div className="grid grid-cols-2 gap-y-2">
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Home Telephone No"
										register={register}
										name="telephone"
										errors={errors}
									/>
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Mobile Telephone No *"
										register={register}
										name="mobileTelephoneNumber"
										errors={errors}
									/>
								</div>
							</div>
							{/* Contact info finsih*/}

							<hr className="my-5" />
							{/* Additional info */}

							<div className="p-5">
								<h1 className="text-4">Additional Information</h1>
								<div className=" grid grid-cols-2 gap-y-2">
									<div className="">
										<FormInput
											containerClass="py-2 max-w-84"
											className="mr-5.5 mt-1.25 w-auto"
											label="National insurance number: *"
											register={register}
											name="nationInsuranceNumber"
											errors={errors}
										/>
										<div className="max-w-84">
											<DateField
												containerClass="max-w-84 py-2 mr-5.5"
												className="mt-1.25 w-full "
												label="Date From"
												setValue={setValue}
												watch={watch}
												name="previousAddressDateFrom"
											/>
											<DateField
												containerClass="max-w-84 py-2 mr-5.5"
												className="mt-1.25 w-full "
												label="Date to"
												setValue={setValue}
												watch={watch}
												name="previousAddressDateTo"
											/>
										</div>
									</div>
									<div className="max-w-84">
										<DateField
											containerClass="max-w-84 py-2 mr-5.5"
											className="mt-1.25 w-full "
											label="Date of birth: *"
											setValue={setValue}
											errors={errors}
											watch={watch}
											name="dateOfBirth"
										/>
										<FormInput
											containerClass="py-2 max-w-84"
											className="mr-5.5 mt-1.25 w-auto"
											label="City: *"
											register={register}
											name="city"
											errors={errors}
										/>
										<FormSelect
											disabled={!allCounties?.length}
											containerClass="max-w-84 py-2 mr-5.5"
											className=" mt-1.25 w-full  "
											register={register}
											errors={errors}
											label="County: *"
											name="county"
											options={allCounties}
										/>
									</div>
									<FormTextArea
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										register={register}
										errors={errors}
										label="Previous Address: (within the last five years):"
										name="previousAddress"
									/>
								</div>
							</div>
							{/* Additional info finish */}
							<hr className="my-5" />

							<div className="p-5">
								<h1 className="text-4">Person to be contacted in emergency</h1>
								<div className="grid grid-cols-2 ">
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Name *"
										register={register}
										name="emergencyContactName"
										errors={errors}
									/>
									<FormTextArea
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										register={register}
										errors={errors}
										label="Address:  *"
										name="emergencyContactAddress"
									/>
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Relation"
										register={register}
										name="emergencyContactRelation"
										errors={errors}
									/>
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Their telephone number at work:"
										register={register}
										name="emergencyContactTelephoneNumberAtWork"
										errors={errors}
									/>
									<FormInput
										containerClass="max-w-84"
										className="mr-5.5 mt-1.25 w-auto"
										label="Their telephone number at home: *"
										register={register}
										name="emergencyContactTelephoneNumberAtHome"
										errors={errors}
									/>
								</div>
							</div>
							<hr className="my-5" />

							<div className="p-5">
								<h1 className="text-4">
									Have you or any of your immediate family ever been convicted, fined, imprisoned,
									placed on probation, discharged on payment of costs of had any order made against
									you by a criminal, civil or military court or public authority (excluding minor
									motoring offences)?
								</h1>
								<div>
									{convicted.map(({ label, value }, i) => (
										<FormInput
											key={`${value}${i}`}
											containerClass="mt-2.75 !flex-row-reverse justify-end"
											className="mr-2 !w-auto"
											register={register}
											errors={errors}
											labelClass="order-1 ml-5.5"
											type="radio"
											value={value}
											label={label}
											id={value}
											name="isConvicted"
										/>
									))}
								</div>

								{watch("isConvicted") === "yes" && (
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="If yes give details:"
										name="isConvictedDetails"
									/>
								)}
							</div>
							<div className="p-5">
								<h1 className="text-4">Do you have any Police Cautions?</h1>
								{/* <div className="grid grid-cols-2 "> */}
								<div>
									{[
										{ label: "yes", value: "yes" },
										{ label: "no", value: "no" },
									].map(({ label, value }, i) => (
										<FormInput
											key={`${value}${i}`}
											containerClass="mt-2.75 !flex-row-reverse justify-end"
											className="mr-2 !w-auto"
											register={register}
											errors={errors}
											labelClass="order-1 ml-5.5"
											type="radio"
											value={value}
											label={label}
											id={value}
											name="policeCautions"
										/>
									))}
								</div>

								{/* </div> */}
								{watch("policeCautions") === "yes" && (
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give details:"
										name="policeCautionsDetails"
									/>
								)}
							</div>
							<div className="p-5">
								<h1 className="text-4">Are any prosecutions pending against you?</h1>
								<div>
									{[
										{ label: "yes", value: "yes" },
										{ label: "no", value: "no" },
									].map(({ label, value }, i) => (
										<FormInput
											key={`${value}${i}`}
											containerClass="mt-2.75 !flex-row-reverse justify-end"
											className="mr-2 !w-auto"
											register={register}
											errors={errors}
											labelClass="order-1 ml-5.5"
											type="radio"
											value={value}
											label={label}
											id={value}
											name="prosecutions"
										/>
									))}
								</div>

								{watch("prosecutions") === "yes" && (
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give details:"
										name="prosecutionsDetails"
									/>
								)}
							</div>
							<div className="p-5">
								<h1 className="text-4">Have you ever been subject to bankruptcy proceedings?</h1>
								<div>
									{[
										{ label: "yes", value: "yes" },
										{ label: "no", value: "no" },
									].map(({ label, value }, i) => (
										<FormInput
											key={`${value}${i}`}
											containerClass="mt-2.75 !flex-row-reverse justify-end"
											className="mr-2 !w-auto"
											register={register}
											errors={errors}
											labelClass="order-1 ml-5.5"
											type="radio"
											value={value}
											label={label}
											id={value}
											name="bankruptcyProceedings"
										/>
									))}
								</div>

								{watch("bankruptcyProceedings") === "yes" && (
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give details:"
										name="bankruptcyProceedingsDetails"
									/>
								)}
							</div>
							<div className="p-5">
								<h1 className="text-4">Are there any outstanding County Court judgements for debt?</h1>
								{/* <div className="grid grid-cols-2 "> */}
								<div>
									{[
										{ label: "yes", value: "yes" },
										{ label: "no", value: "no" },
									].map(({ label, value }, i) => (
										<FormInput
											key={`${value}${i}`}
											containerClass="mt-2.75 !flex-row-reverse justify-end"
											className="mr-2 !w-auto"
											register={register}
											errors={errors}
											labelClass="order-1 ml-5.5"
											type="radio"
											value={value}
											label={label}
											id={value}
											name="isCountryCourtJudgmentsDebt"
										/>
									))}
								</div>

								{/* </div> */}
								{watch("isCountryCourtJudgmentsDebt") === "yes" && (
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give details:"
										name="isCountryCourtJudgmentsDebtDetails"
									/>
								)}
							</div>
							{watch("isCountryCourtJudgmentsDebt") === "yes" && (
								<div className="p-5">
									<h1 className="text-4">
										If so, please supply details of all financial judgements made against them in
										the civil court and any voluntary arrangements (IVA’s) with creditors from the
										previous six years.
									</h1>
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give details:"
										name="financialsJudgementsDetails"
									/>
								</div>
							)}

							<hr className="my-5" />

							<div className="p-5">
								<div className="grid grid-cols-2">
									<div>
										<h1 className="text-4">Have you any relatives working for the company?</h1>
										<div>
											{[
												{ label: "yes", value: "yes" },
												{ label: "no", value: "no" },
											].map(({ label, value }, i) => (
												<FormInput
													key={`${value}${i}`}
													containerClass="mt-2.75 !flex-row-reverse justify-end"
													className="mr-2 !w-auto"
													register={register}
													errors={errors}
													labelClass="order-1 ml-5.5"
													type="radio"
													value={value}
													label={label}
													id={value}
													name="isRelativesWorkingForCompany"
												/>
											))}
										</div>
										{watch("isRelativesWorkingForCompany") === "yes" && (
											<FormInput
												containerClass="max-w-84"
												className="mr-5.5 mt-1.25 w-auto"
												label="IF yes please state name:"
												register={register}
												name="isRelativesWorkingForCompanyName"
												errors={errors}
											/>
										)}
									</div>
									<div>
										<h1 className="text-4">
											Have you previously applied for or obtained a position with this company?
										</h1>
										<div className="grid grid-cols-2 ">
											<div>
												{[
													{ label: "yes", value: "yes" },
													{ label: "no", value: "no" },
												].map(({ label, value }, i) => (
													<FormInput
														key={`${value}${i}`}
														containerClass="mt-2.75 !flex-row-reverse justify-end"
														className="mr-2 !w-auto"
														register={register}
														errors={errors}
														labelClass="order-1 ml-5.5"
														type="radio"
														value={value}
														label={label}
														id={value}
														name="previouslyAppliedOrPosition"
													/>
												))}
											</div>
										</div>
										{watch("previouslyAppliedOrPosition") === "yes" && (
											<DateField
												containerClass="max-w-84 py-2 mr-5.5"
												className="mt-1.25 w-full "
												label="If yes Dates:"
												setValue={setValue}
												watch={watch}
												name="previouslyAppliedOrPositionDate"
											/>
										)}
									</div>
								</div>
							</div>

							<hr className="my-5" />

							<div className="p-5">
								<div className="grid grid-cols-2">
									<div>
										<h1 className="text-4">Do you own a motor vehicle or motor cycle?</h1>
										<div>
											{[
												{ label: "yes", value: "yes" },
												{ label: "no", value: "no" },
											].map(({ label, value }, i) => (
												<FormInput
													key={`${value}${i}`}
													containerClass="mt-2.75 !flex-row-reverse justify-end"
													className="mr-2 !w-auto"
													register={register}
													errors={errors}
													labelClass="order-1 ml-5.5"
													type="radio"
													value={value}
													label={label}
													id={value}
													name="isOwnVehicleOrCycle"
												/>
											))}
										</div>
									</div>
									<div>
										<h1 className="text-4">
											Do you possess a full, clean, current UK Driving Licence?
										</h1>
										<div>
											{[
												{ label: "yes", value: "yes" },
												{ label: "no", value: "no" },
											].map(({ label, value }, i) => (
												<FormInput
													key={`${value}${i}`}
													containerClass="mt-2.75 !flex-row-reverse justify-end"
													className="mr-2 !w-auto"
													register={register}
													errors={errors}
													labelClass="order-1 ml-5.5"
													type="radio"
													value={value}
													label={label}
													id={value}
													name="isUKDrivingLicence"
												/>
											))}
										</div>
									</div>
								</div>

								<div className="mt-5">
									<h1 className="text-4">How long have you held a full Driving Licence?</h1>
									<div className="mb-4 flex w-full  items-start gap-2.5">
										<FormInput
											className="mt-1 w-full"
											name="hours"
											register={register}
											errors={errors}
										/>
										<div className="flex flex-row items-center gap-4">
											<span className="text-dark-alt">Years</span>
											<FormInput
												className=" w-full"
												name="minutes"
												register={register}
												errors={errors}
											/>
											<span className="text-dark-alt">Months </span>
										</div>
									</div>
								</div>
								<div className="mt-5 flex flex-row items-center gap-1">
									<h1 className="text-4">Driving Licence No:</h1>

									<FormInput
										containerClass="w-1/4"
										className=" w-full"
										name="drivingLicenceNo"
										register={register}
										errors={errors}
									/>
									<h1 className="text-4">Issue Date:</h1>
									<FormInput
										className=" w-full"
										name="drivingLicenceIssueDate"
										register={register}
										errors={errors}
									/>
									<h1 className="text-4">Expiry Date:</h1>
									<FormInput
										className=" w-full"
										name="drivingLicenceExpiryDate"
										register={register}
										errors={errors}
									/>
								</div>
								<div className="mt-5">
									<h1 className="text-4">
										Give details of any endorsements (If any) or other motoring convictions during
										the last five years:
									</h1>
									<FormTextArea
										containerClass="mr-5.5 max-w-84 w-full mt-2"
										className="!w-auto"
										register={register}
										errors={errors}
										label="Give Details"
										name="endorsmentsOrMotoringConvictions"
									/>
								</div>
							</div>
							<hr className="my-5" />
							<div className="p-5">
								<div className="">
									<h1 className="text-4">
										Education and Qualifications (State name and address of last school/college
										attended).
									</h1>
									<div className="grid grid-cols-3">
										<FormInput
											containerClass="max-w-84"
											className="mr-5.5 mt-1.25 w-auto"
											label="Secondary
											School/College or
											University attended"
											register={register}
											name="education.0.instituteName"
											errors={errors}
										/>
										<DateField
											containerClass="max-w-84 mr-5.5 justify-end"
											className="mt-1.25 w-full "
											label="Dates"
											setValue={setValue}
											watch={watch}
											name="education.0.dates"
										/>
										<FormInput
											containerClass="max-w-84 justify-end"
											className="mr-5.5 mt-1.25 w-auto"
											label="Exams taken, qualification
											gained"
											register={register}
											name="education.0.examTaken"
											errors={errors}
										/>
										<FormInput
											containerClass="max-w-84 mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											name="education.1.instituteName"
											errors={errors}
										/>
										<DateField
											containerClass="max-w-84 mr-5.5 justify-end mt-2"
											className="mt-1.25 w-full "
											setValue={setValue}
											watch={watch}
											name="education.1.dates"
										/>
										<FormInput
											containerClass="max-w-84 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											name="education.1.examTaken"
											errors={errors}
										/>
									</div>
									<div className="mt-2 flex w-full">
										<FormTextArea
											containerClass="w-1/2 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="First Aid/Fire Fighting Certificates:"
											name="otherMedicalCertificates"
											errors={errors}
										/>
										<FormTextArea
											containerClass="w-1/2 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Foreign languages:"
											name="foreignLanguages"
											errors={errors}
										/>
									</div>
								</div>
							</div>
							<hr className="my-5" />
							<div className="p-5">
								<div className="">
									<div>
										<h1 className="text-4">May we approach your employer(s)?</h1>
										<div>
											{[
												{ label: "yes", value: "yes" },
												{ label: "no", value: "no" },
											].map(({ label, value }, i) => (
												<FormInput
													key={`${value}${i}`}
													containerClass="mt-2.75 !flex-row-reverse justify-end"
													className="mr-2 !w-auto"
													register={register}
													errors={errors}
													labelClass="order-1 ml-5.5"
													type="radio"
													value={value}
													label={label}
													id={value}
													name="living"
												/>
											))}
										</div>
									</div>
									<h1 className="mt-4 text-4">Employment History (Vetting Form).</h1>
									<p>
										Starting with your last or present employer, give details of your employment
										history, with FULL POSTAL ADDRESS, for the last five years or since you left
										full time education. Include periods of self-employment and military service.
										Please state any periods of unemployment that you did not register with the
										department of unemployment as “not registered/unemployed” and give full details
										of what you were doing. If self employed you must give name, address and
										telephone number of your accountant
									</p>
									{[1, 2, 3, 4, 5].map((ind, index) => (
										<div className="my-4 rounded-md  p-5" key={`${ind}-${index}`}>
											<h1 className="text-4">{ind}.</h1>
											<h2>
												Name and full address of current/last employer or jobcentre/DSS office
											</h2>
											<div className=" grid grid-cols-4">
												<FormInput
													containerClass="max-w-84 justify-end"
													className="mr-5.5 mt-1.25 w-auto"
													label="Name"
													register={register}
													name={`employerHistory.${index}.name`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 justify-end"
													className="mr-5.5 mt-1.25 w-auto"
													label="Address"
													register={register}
													name={`employerHistory.${index}.address`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 justify-end"
													className="mr-5.5 mt-1.25 w-auto"
													label="Postcode"
													register={register}
													name={`employerHistory.${index}.postcode`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 mt-2"
													className="mr-5.5 mt-1.25 w-auto"
													register={register}
													label="Tel"
													name={`employerHistory.${index}.telephone`}
													errors={errors}
												/>
											</div>
											<hr className="my-2" />
											<h2>Details</h2>
											<div className=" grid grid-cols-4">
												<FormInput
													containerClass="max-w-84 justify-end mt-2"
													className="mr-5.5 mt-1.25 w-auto"
													register={register}
													label="Position Held"
													name={`employerHistory.${index}.positionHeld`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 justify-end mt-2"
													className="mr-5.5 mt-1.25 w-auto"
													register={register}
													label="Reporting to"
													name={`employerHistory.${index}.reportingTo`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 justify-end mt-2"
													className="mr-5.5 mt-1.25 w-auto"
													register={register}
													label="Basic Wage"
													name={`employerHistory.${index}.basicWage`}
													errors={errors}
												/>
												<FormInput
													containerClass="max-w-84 justify-end mt-2"
													className="mr-5.5 mt-1.25 w-auto"
													register={register}
													label="Reason for leaving:"
													name={`employerHistory.${index}.reasonOfLeaving`}
													errors={errors}
												/>
											</div>
											<hr className="my-2" />
											<h2>Unemployment/ Employment Date</h2>
											<div className=" grid grid-cols-4">
												<DateField
													containerClass="max-w-84 mr-5.5 justify-end mt-2"
													className="mt-1.25 w-full "
													setValue={setValue}
													label="From"
													watch={watch}
													name={`employerHistory.${index}.startDate`}
												/>
												<DateField
													containerClass="max-w-84 mr-5.5 justify-end mt-2"
													className="mt-1.25 w-full "
													setValue={setValue}
													label="To"
													watch={watch}
													name={`employerHistory.${index}.endDate`}
												/>
											</div>
											<hr className="my-5" />
										</div>
									))}
								</div>
							</div>
							<hr className="my-5" />
							<div className="p-5">
								<div className="">
									<h1 className="text-4">Personal References.</h1>
									<div className="grid grid-cols-2">
										<FormInput
											containerClass="max-w-84"
											className="mr-5.5 mt-1.25 w-auto"
											label="Name"
											register={register}
											name="referenceName"
											errors={errors}
										/>

										<FormInput
											containerClass="max-w-84 mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Postcode"
											name="referencePostcode"
											errors={errors}
										/>

										<FormInput
											containerClass="max-w-84 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Tel"
											name="referenceTelephone"
											errors={errors}
										/>
										<FormInput
											containerClass="max-w-84 mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Occupation"
											name="referenceOccupation"
											errors={errors}
										/>
										<FormTextArea
											containerClass="w-1/2 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											label="Address"
											register={register}
											name="referenceAddress"
											errors={errors}
										/>
										<FormInput
											containerClass="max-w-84 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="How long Known"
											name="referenceHowLongKnown"
											errors={errors}
										/>
									</div>
								</div>
							</div>
							<hr className="my-5" />
							<div className="p-5">
								<div className="">
									<h1 className="text-4">Bank Details.</h1>
									<div className="grid grid-cols-2">
										<FormInput
											containerClass="max-w-84"
											className="mr-5.5 mt-1.25 w-auto"
											label="Name of Bank"
											register={register}
											name="bankName"
											errors={errors}
										/>

										<FormInput
											containerClass="max-w-84 justify-end"
											className="mr-5.5 mt-1.25 w-auto"
											label="Bank Account Number"
											placeholder="12345678"
											register={register}
											name="bankAccountNumber"
											errors={errors}
										/>
										<FormInput
											containerClass="max-w-84 mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Bank Sort Code"
											placeholder="12-34-56"
											name="bankSortCode"
											errors={errors}
										/>
										<FormInput
											containerClass="max-w-84 mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Account Holders Name"
											name="accountHoldersName"
											errors={errors}
										/>
										<FormTextArea
											containerClass="w-1/2 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Address of Bank:"
											name="bankAddress"
											errors={errors}
										/>
										<FormTextArea
											containerClass="w-1/2 justify-end mt-2"
											className="mr-5.5 mt-1.25 w-auto"
											register={register}
											label="Role Number:"
											placeholder="(Applicable to Building
												Society Accounts Only)"
											name="bankRoleNumber"
											errors={errors}
										/>
									</div>
								</div>
							</div>
						</div>
						{disableSubmit ? (
							<>
								{watch("approved") !== true && (
									<>
										<div className=" mx-auto flex h-72  flex-col bg-slate-200 p-5 ">
											<div className="h-[25%] flex-col ">
												<h1 className=" text-4">Select Branch/Supplier for Employee</h1>

												{error.value && <p className="text-red-500"> Please Select One * </p>}
											</div>
											<div className="grid h-[50%] grid-cols-2">
												<FormSelect
													onChange={({ target: { value } }) => {
														setValue("supplier", "")
														setValue("branch", value)
														if (value) setError({ ...error, value: false })
													}}
													disabled={!branches.length}
													containerClass="max-w-84 w-84"
													className="mr-5.5 mt-1.25 w-auto "
													label="Branch"
													name="branch"
													errors={errors}
													register={register}
													options={[{ label: "Select Branch", value: "" }, ...branches]}
												/>
												<FormSelect
													onChange={({ target: { value } }) => {
														setValue("branch", "")
														setValue("supplier", value)
														if (value) setError({ ...error, value: false })
													}}
													disabled={!suppliers.length}
													containerClass="max-w-84 w-84"
													className="mr-5.5 mt-1.25 w-auto "
													label="Supplier"
													name="supplier"
													errors={errors}
													register={register}
													options={suppliers}
												/>
												<div>
													<FormInputPassword
														containerClass="max-w-84 w-full mt-2"
														className="mr-5.5  w-auto "
														register={register}
														onChange={({ target: { value } }) => {
															setValue("password", value)
															if (value) setError({ ...error, password: false })
														}}
														errors={errors}
														label="Password"
														name="password"
													/>
													{error.password && (
														<p className="text-red-500">
															{!watch("password") && "Please Enter Password *"}
														</p>
													)}
												</div>
												<div>
													<FormInputPassword
														containerClass="max-w-84 w-full mt-2"
														className="mr-5.5  w-auto "
														register={register}
														onChange={({ target: { value } }) => {
															setValue("confirmPassword", value)
															if (value) setError({ ...error, password: false })
														}}
														errors={errors}
														label="Confirm Password"
														name="confirmPassword"
													/>
													{error.password && (
														<p className="text-red-500">
															{watch("password") !== watch("confirmPassword") &&
																"Confirm Password should be same as Password"}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="mx-4 my-4 flex h-auto  justify-end space-x-10 ">
											<Button
												type="button"
												loading={handling}
												onClick={() => handleApprove({ ...getValues(), approved: true })}
											>
												Approve
											</Button>
										</div>
									</>
								)}
							</>
						) : (
							<div className="m-5 flex justify-end">
								<Button loading={saving}>Submit</Button>
							</div>
						)}
					</form>
				</div>
			</div>
		</Section>
	)
}

export default JavForm
