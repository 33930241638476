import React, { Children, useState, useEffect, useMemo } from "react"
import cx from "classnames"
import useIsOverflowY from "hooks/useIsOverflowY"
import { Permissions } from "config/constants"
import { useAbac } from "react-abac"
import { twMerge } from "tailwind-merge"

interface Children {
	children: React.ReactNode
}
interface Props extends Children {
	initialTab: number
	disabled?: boolean
	overflowClass?: string
	selectedTabNumber?: (data: number) => void
	containerClass?: string
}

interface TabProps extends Children {
	title: string
	disabled?: boolean
	hasHeader?: boolean
	shouldOverflow?: boolean
	perform?: Permissions | Permissions[] | string
}

export const Tabs: React.FC<Props> = ({
	children,
	initialTab = 0,
	disabled = false,
	overflowClass = "",
	containerClass = "",
	selectedTabNumber = () => undefined,
}) => {
	const { userHasPermissions } = useAbac()
	const [selectedTab, setSelectedTab] = useState(initialTab)
	useEffect(() => {
		if (disabled) setSelectedTab(0)
	}, [disabled])

	useEffect(() => {
		selectedTabNumber(selectedTab)
	}, [selectedTab])

	children = useMemo(() => {
		return Children.toArray(children).filter((item: any) => userHasPermissions(item?.props?.perform || []))
	}, [selectedTab, children, disabled])

	const tabToRender = useMemo(() => Children.toArray(children).find((_, index) => index === selectedTab), [children])

	return (
		<>
			<div className={twMerge("mx-2.5 flex flex-wrap border-b-0.125", containerClass)}>
				{Children.map(children, (tab: any, tabIndex) => {
					const { title, disabled = false }: TabProps = tab.props

					return (
						<div
							key={title}
							className={cx(
								`mr-2 mt-1 flex flex-col items-center rounded-md ${
									overflowClass.length && "mr-[0.5px]"
								}`,
								{
									"bg-red": disabled,
									"bg-background": tabIndex === selectedTab,
								},
							)}
						>
							<button
								disabled={disabled}
								onClick={() => {
									!disabled && setSelectedTab(tabIndex)
								}}
								className={cx(`px-6 py-1.5 text-center font-semibold text-dark-alt ${overflowClass}`, {
									"disabled:opacity-25": disabled,
								})}
							>
								{title}
							</button>
							<div
								className={`${tabIndex === selectedTab ? "bg-purple" : ""} h-0.75 w-full  rounded-t-md`}
							></div>
						</div>
					)
				})}
			</div>

			<div className="">{tabToRender}</div>
		</>
	)
}

export const Tab: React.FC<TabProps> = ({ children, hasHeader = false, shouldOverflow = true, ...rest }) => {
	const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>
	const [isOverflowY, setIsOverflowY] = useState(false)
	useIsOverflowY(ref, (isOverflowY: boolean) => setIsOverflowY(isOverflowY))

	return (
		<div
			ref={ref}
			className={`overflow-y-scroll rounded-2 p-2.5 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500 ${
				isOverflowY ? "shadow-tab" : ""
			}`}
			style={{ height: shouldOverflow ? `calc(100vh - ${hasHeader ? "250px" : "130px"})` : "fit-content" }}
			{...rest}
		>
			{children}
		</div>
	)
}
