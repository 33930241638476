import React, { useState } from "react"
import moment from "moment"
import useAppSelector from "hooks/useAppSelector"
import { selectUserById } from "store/Security/users.slice"
import { PreviousShiftEvent } from "@type/workMonitor.types"
// import Image from "components/Image"
import { Image } from "antd-v5"
import { Icon, Tooltip, SignedUrlForImage } from "components/index"
import { twMerge } from "tailwind-merge"

interface Props {
	events: PreviousShiftEvent
	containerClass?: string
	shiftStatusColor: string
}

const ShiftEventCard: React.FC<Props> = ({ events, shiftStatusColor, containerClass }) => {
	const [showDropDown, setShowDropDown] = useState<boolean>(false)
	const handleDropDown = () => {
		setShowDropDown(prev => !prev)
	}
	const user = useAppSelector(selectUserById(events?.user?._id ?? events?.user ?? null))

	return (
		<div className="m-2 cursor-pointer rounded-md border-[0.75px] border-dark/20 px-2 pb-2 font-semibold">
			<div className="flex flex-row items-center pt-2 3xl:flex-row" onClick={handleDropDown}>
				<div>
					<Icon name={showDropDown ? "dropdownArrow" : "dropRightArrow"} size={20} color="black" />
				</div>
				<div className="flex w-full flex-col items-center justify-center 3xl:flex-row 3xl:justify-between">
					<div className="flex items-center space-x-2">
						<div
							className={twMerge(
								`h-fit rounded-2 text-white ${shiftStatusColor} px-2 text-sm font-semibold`,
							)}
						>
							{events.status}
						</div>
						{!!events?.isEventMissed && (
							<div className="flex h-6 w-6 items-center justify-center">
								<Tooltip message="Missed!">
									<Icon name="cross" size={20} color="danger" />
								</Tooltip>
							</div>
						)}
					</div>

					<div className="flex items-center space-x-0 sm:flex-col 3xl:flex-row 3xl:space-x-5">
						{!events?.isEventMissed && (
							<span className="col-span-4">
								Event Time: {moment(events?.eventTime || events.createdAt).format("HH:mm")}
							</span>
						)}
						<span className="col-span-4">
							Due Time:{" "}
							{moment(events.dueTime).isValid() ? moment(events.dueTime).format("HH:mm") : events.dueTime}
						</span>
					</div>
				</div>
			</div>

			<div className={`flex flex-col space-y-2 py-2 ${showDropDown ? "visible" : " hidden"} `}>
				{events.geo?.coordinates.length ? (
					<span>
						Coordinates : {events.geo?.coordinates[0]},{events.geo?.coordinates[1]}
					</span>
				) : undefined}
				{events.images?.length ? (
					<div className={`grid grid-cols-4 gap-x-3 p-2 ${containerClass?.length ? " grid-cols-7" : ""}`}>
						<Image.PreviewGroup>
							{events.images?.map((imageUrl, index) => {
								return (
									<SignedUrlForImage path={imageUrl} key={`loadedImage_${index}`}>
										{(image: string) => <Image src={image} />}
									</SignedUrlForImage>
								)
							})}
						</Image.PreviewGroup>
					</div>
				) : (
					<></>
				)}
				{user && <span>{`Performed By: ${user?.firstName} ${user?.lastName}`}</span>}
				{!!events.comment?.length && <span>Comment: {events.comment} </span>}
			</div>
		</div>
	)
}

export default ShiftEventCard
