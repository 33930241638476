import { combineReducers } from "@reduxjs/toolkit"
import authReducer from "./auth.slice"
import globalStateReducer from "./globalState.slice"
import mediaReducer from "./Media/media.slice"
import branchReducer from "./Branches/branch.slice"
import EmployeeReducer from "./Employee"
import LocationReducer from "./Location"
import CustomersReducer from "./Customers/customers.slice"
import payGroupReducer from "./PayGroups/payGroup.slice"
import schedulesReducer from "./Schedules/schedules.slice"
import workMonitorReducer from "./workMonitor/workMonitor.slice"
import organizationReducer from "./Organization"
import securityReducer from "./Security"
import PayBillReducer from "./PayBill"
import StaticDataReducer from "./StaticData"
import GeolocationReducer from "./Geolocation/geolocation.slice"
import AuditTrailReducer from "./AuditTrail/auditTrail.slice"
import SDBillGroupReducer from "./StaticData/SDBillGroup.slice"
import LoginTrailReducer from "./LoginTrail/loginTrail.slice"
import JafReducer from "./Jaf/jaf.slice"
import PayrollReducer from "./Account/payroll.slice"
const rootReducer = combineReducers({
	auth: authReducer,
	globalState: globalStateReducer,
	employee: EmployeeReducer,
	location: LocationReducer,
	branch: branchReducer,
	media: mediaReducer,
	customers: CustomersReducer,
	payGroups: payGroupReducer,
	schedules: schedulesReducer,
	workMonitor: workMonitorReducer,
	organization: organizationReducer,
	security: securityReducer,
	payBill: PayBillReducer,
	staticData: StaticDataReducer,
	geolocation: GeolocationReducer,
	auditTrail: AuditTrailReducer,
	billGroup: SDBillGroupReducer,
	loginTrail: LoginTrailReducer,
	jaf: JafReducer,
	payroll: PayrollReducer,
})

export default rootReducer
