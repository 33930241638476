import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "store"

export interface GlobalStateInterface {
	selectedBranch: string
	selectedLocationBranch: string
	selectedEmployeeBranch: string
	selectedCustomer?: string
	selectedSupplier?: string
	selectedEmployee: string
	selectedLocation: string
}

const initialState: GlobalStateInterface = {
	selectedBranch: "",
	selectedLocationBranch: "",
	selectedEmployeeBranch: "",
	selectedCustomer: "",
	selectedSupplier: "",
	selectedEmployee: "",
	selectedLocation: "",
}

const globalStateSlice = createSlice({
	name: "globalState",
	initialState,
	reducers: {
		setSelectedBranch: (state, action: PayloadAction<string>) => {
			state.selectedBranch = action.payload
			state.selectedLocationBranch = action.payload
			state.selectedEmployeeBranch = action.payload
			state.selectedSupplier = ""
		},
		setSelectedLocationBranch: (state, action: PayloadAction<string>) => {
			state.selectedLocationBranch = action.payload
		},
		setSelectedEmployeeBranch: (state, action: PayloadAction<string>) => {
			state.selectedEmployeeBranch = action.payload
			state.selectedSupplier = ""
		},
		setSelectedCustomer: (state, action: PayloadAction<string>) => {
			state.selectedCustomer = action.payload
		},
		clearSelectedCustomer: state => {
			state.selectedCustomer = ""
		},
		clearSelectedBranch: state => {
			state.selectedBranch = ""
			state.selectedLocationBranch = ""
			state.selectedEmployeeBranch = ""
		},
		setSelectedSupplier: (state, action: PayloadAction<string>) => {
			state.selectedSupplier = action.payload
			state.selectedBranch = ""
			state.selectedLocationBranch = ""
			state.selectedEmployeeBranch = ""
		},
		clearSelectedSupplier: state => {
			state.selectedSupplier = ""
		},
		setSelectedEmployee: (state, action: PayloadAction<string>) => {
			state.selectedEmployee = action.payload
		},
		clearSelectedEmployee: state => {
			state.selectedEmployee = ""
		},
		setSelectedLocation: (state, action: PayloadAction<string>) => {
			state.selectedLocation = action.payload
		},
		clearSelectedLocation: state => {
			state.selectedLocation = ""
		},
	},
})

// REDUCER
export default globalStateSlice.reducer

// ACTIONS
export const {
	setSelectedBranch,
	clearSelectedBranch,
	setSelectedCustomer,
	clearSelectedCustomer,
	setSelectedSupplier,
	clearSelectedSupplier,
	setSelectedEmployee,
	clearSelectedEmployee,
	setSelectedLocation,
	clearSelectedLocation,
	setSelectedLocationBranch,
	setSelectedEmployeeBranch,
} = globalStateSlice.actions

// SELECTORS
const selectSelectedGlobalState = (state: RootState) => state.globalState
const selectSelectedBranch = (state: RootState) => selectSelectedGlobalState(state).selectedBranch
const selectSelectedLocationBranch = (state: RootState) => selectSelectedGlobalState(state).selectedLocationBranch
const selectSelectedEmployeeBranch = (state: RootState) => selectSelectedGlobalState(state).selectedEmployeeBranch
const selectSelectedCustomer = (state: RootState) => selectSelectedGlobalState(state).selectedCustomer
const selectSelectedSupplier = (state: RootState) => selectSelectedGlobalState(state).selectedSupplier
const selectSelectedEmployee = (state: RootState) => selectSelectedGlobalState(state).selectedEmployee
const selectSelectedLocation = (state: RootState) => selectSelectedGlobalState(state).selectedLocation

const selectSelectedLocationDetails = createSelector(
	(state: RootState) => ({
		locationList: state.location.details.list || [],
		selectedLocationId: state.globalState.selectedLocation,
	}),
	({ locationList, selectedLocationId }) => locationList.find(list => list._id === selectedLocationId),
)

export {
	selectSelectedLocationBranch,
	selectSelectedEmployeeBranch,
	selectSelectedBranch,
	selectSelectedCustomer,
	selectSelectedLocation,
	selectSelectedSupplier,
	selectSelectedEmployee,
	selectSelectedLocationDetails,
}
