import React from "react"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "config"
import NavbarMenu from "./NavbarMenu"
import { Permissions } from "config/constants"
import { useAbac } from "react-abac"
import { Tabs, ConfigProvider } from "antd-v5"

export interface NAVBAR_OPTION {
	label: string
	path: AppRoutes
	perform: Permissions | Permissions[]
}
export const NAVBAR_OPTIONS: {
	[key: string]: NAVBAR_OPTION[]
} = {
	schedules: [
		{ label: "Schedules", path: AppRoutes.SCHEDULES_PAGE, perform: [] },
		{ label: "Employees", path: AppRoutes.EMPLOYEES, perform: [] },
		{ label: "Locations", path: AppRoutes.LOCATION, perform: [] },
		{ label: "Command Center", path: AppRoutes.WORK_MONITOR, perform: Permissions.VIEW_WORKMONITOR },
		{ label: "Job application", path: AppRoutes.JAF, perform: [] },
		// { label: "Accounts", path: AppRoutes.ACCOUNTS, perform: Permissions.VIEW_PAYROLL },
		{ label: "Analytics", path: AppRoutes.DASHBOARD, perform: Permissions.VIEW_ADMINISTRATION },
	],
	admin: [
		{ label: "Security", path: AppRoutes.ADMIN_PAGE, perform: [] },
		// { label: "Organization", path: AppRoutes.ORGANIZATION, perform: [] },
		{ label: "PayBill", path: AppRoutes.PAY_BILL, perform: [] },
		{ label: "Static Data", path: AppRoutes.STATIC_DATA, perform: [] },
	],
	reports: [],
}
interface Props {
	panel: string
	active: number
	loaderRef: any
}

const NavBar: React.FC<Props> = ({ panel, active, loaderRef }) => {
	const navigate = useNavigate()
	const { userHasPermissions } = useAbac()
	const onChange = (key: string) => {
		loaderRef?.current?.continuousStart()
		const indexOfKey = NAVBAR_OPTIONS[panel].findIndex(item => item.label === key)
		setTimeout(() => navigate(NAVBAR_OPTIONS[panel][indexOfKey].path), 500)
	}
	if (active === -1) return <></>
	return (
		<ConfigProvider prefixCls="ant5" theme={{ components: { Tabs: { horizontalMargin: "0" } } }}>
			<Tabs
				size="middle"
				activeKey={NAVBAR_OPTIONS[panel].find((_, i) => i === active)?.label}
				defaultActiveKey={NAVBAR_OPTIONS[panel].find((_, i) => i === active)?.label}
				onChange={onChange}
				tabBarExtraContent={{ right: <NavbarMenu /> }}
				items={NAVBAR_OPTIONS[panel]
					?.filter(({ perform }: { perform: string | string[] }) => userHasPermissions(perform))
					.map(({ label }) => ({
						key: label,
						label: label,
					}))}
			/>
		</ConfigProvider>
	)
}

export default NavBar
