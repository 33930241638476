import moment from "moment-timezone"
import { caseConverters } from "./utils"

export enum ShiftStatus {
	"BOOKOFF" = "BOOKOFF",
	"BOOKON" = "BOOKON",
	"CHECK" = "CHECK",
	"CHASED" = "CHASED",
	"PENDING" = "TO BE CHASED",
	"INVENTORY" = "INVENTORY",
	"QR" = "QR",
}

export const SHIFT_NEXT_EVENTS_PRIORITY = [ShiftStatus.QR, ShiftStatus.INVENTORY, ShiftStatus.CHECK]

export const nextStatusToPriorityMap = {
	[ShiftStatus.BOOKON]: 1,
	[ShiftStatus.CHASED]: 2,
	[ShiftStatus.BOOKOFF]: 5,
	[ShiftStatus.CHECK]: 4,
	[ShiftStatus.PENDING]: 6,
	[ShiftStatus.INVENTORY]: 3,
	[ShiftStatus.QR]: 3,
}
export enum DefaultEscalationTypeEnum {
	"CALL" = "CALL",
	"ALARM" = "ALARM",
	"STAFF_ABSENCE" = "STAFF_ABSENCE",
	"OTHER" = "OTHER",
}

export const selectDefaultEscalationType = Object.keys(DefaultEscalationTypeEnum).map(escalationType => ({
	label: caseConverters.titleCase(escalationType) as string,
	value: escalationType,
}))

export const EPOCH_TIME_FORMAT = "1970-01-01"

export enum EmployeeTypesEnum {
	"SECURITY" = "SECURITY",
	"CLEANING" = "CLEANING",
}

export enum LocationDocumentTypes {
	"GENERAL" = "GENERAL",
	"ASSIGNMENT_INSTRUCTIONS" = "ASSIGNMENT_INSTRUCTIONS",
}

export const locationDocumentOptions = Object.values(LocationDocumentTypes).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

export enum ScheduleTypesEnum {
	"DUTY" = "DUTY",
	"TRAINING" = "TRAINING",
	"HOLIDAY" = "HOLIDAY",
	"SICKNESS" = "SICKNESS",
}

export const byLocationScheduleType = Object.values(ScheduleTypesEnum)
	.filter(item => item === ScheduleTypesEnum.DUTY || item === ScheduleTypesEnum.TRAINING)
	.map(item => ({
		label: caseConverters.titleCase(item),
		value: item,
	}))

export const byEmployeeScheduleType = Object.values(ScheduleTypesEnum).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

export const employeeTypeOptions = Object.values(EmployeeTypesEnum).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

export const timeZones = moment.tz.names().map(item => ({ label: item, value: item }))

export const OneMbInBytes = 1048576
export const TwoPointThreeMbInBytes = 2300000

export enum DATE_FORMATS {
	YYYY_MM_DD = "yyyy-MM-DD",
	DD_MM_YYYY = "DD-MM-YYYY",
	DD_MM_YYYY_HH_MM = "DD-MM-YYYY HH:mm",
	DD_MM_YYYY_HH_MM_SS = "DD-MM-YYYY HH:mm:ss",
	MMMM_Do_YYYY_h_mm_ss_a = "dddd MMMM Do YYYY, h:mm:ss a",
	HH_mm = "HH:mm",
	HH_mm_ss = "HH:mm:ss",
	HHmm = "HHmm",
	dddd = "dddd",
	SHIFT_EXPECTED_TIME_FORMAT = "ddd DD/MM/YY HH:mm",
	SHOW_TIME_TOP = "MMMM Do YYYY, HH:mm",
	YYYY_M_D_H_m = "YYYY-M-D HH:mm",
	YYYY_MM_DD_T_HH_MM = "YYYY-MM-DDThh:mm",
	YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDThh:mm:ss",
	YYYY_MM_DD_T_HH_MM_SS1 = "YYYY-MM-DDTHH:mm:ss",
	REPORT_DD_MM_YYYY = "DD/MM/YYYY",
	REPORT_DD_MM_YYYY_HH_mm = "DD/MM/YYYY HH:mm",
}

export const GLOBAL_DATE_FORMAT = DATE_FORMATS.DD_MM_YYYY
export const GLOBAL_PAYLOAD_DATE_FORMAT = DATE_FORMATS.YYYY_MM_DD
export const GLOBAL_TIME_FORMAT = DATE_FORMATS.HHmm

export const VALIDATION_REGEX = {
	postalCode:
		/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/,
	mobile: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?(\d{4}|\d{3}))?$/,
	phone: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?(\d{4}|\d{3}))?$/,
	nin: /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/,
	sia: /^\d{16,}$/,
	TIME_HH_MM: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
	pin: /^\d{4}$/,
	mongoId: /^[a-f\d]{24}$/i,
	usernameCheckI: /^([a-zA-Z0-9._]+)$/, // contain only letters number dots and underscores
	usernameCheckII: /^(?!.*[._]{2})/, // can not contain consecutive dots or underscores
	usernameCheckIII: /^(?![._])(?!.*[._]$)/, // can not start or end with dot or underscores
	// userName: /^(?=[a-zA-Z0-9.]{6,20}$)(?!.*[.]{2})[^.].*[^.]$/,
}

export enum CallWindow {
	// Call window in minutes
	BEFORE = 15,
	AFTER = 10,
}

export enum Time {
	EPOCHTIME = "1970-01-01",
	INVALID_TIME = "Invalid date",
}

export enum constraintColor {
	DANGER = "bg-red-400",
	WARNING = "bg-orange-100",
	SUCCESS = "bg-teal-100",
}

//* Organization ---> Notifiees
export enum NotificationBy {
	EMAIL = "EMAIL",
	PHONE_NUMBER = "PHONE_NUMBER",
	OTHER = "OTHER",
}

export const NotificationByOptions = Object.values(NotificationBy).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* Organization ---> WorkMonitorView
export enum ConsistsOf {
	BRANCH = "BRANCH",
}

export const ConsistsOfOptions = Object.values(ConsistsOf).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* Security ---> Roles
export enum PermitterForEnum {
	LOCATION = "LOCATIONS",
	BRANCH = "BRANCHES",
	EMPLOYEE = "EMPLOYEES",
	CUSTOMER = "CUSTOMERS",
	SUPPLIER = "SUPPLIERS",
	COMPANY = "COMPANY",
}

export const PermitterForOptions = Object.values(PermitterForEnum).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* Security ---> Roles
export enum Permissions {
	ALLOW_DOCUMENT_UPDATE_FOR_EMPLOYEE = "ALLOW_DOCUMENT_UPDATE_FOR_EMPLOYEE",
	ALLOW_DOCUMENT_UPDATE_VIEW_FOR_LOCATION = "ALLOW_DOCUMENT_UPDATE_VIEW_FOR_LOCATION",
	ALLOW_DOCUMENT_VIEW_FOR_EMPLOYEE = "ALLOW_DOCUMENT_VIEW_FOR_EMPLOYEE",
	ALLOW_DOCUMENT_VIEW_FOR_LOCATION = "ALLOW_DOCUMENT_VIEW_FOR_LOCATION",
	ALLOW_SCHEDULE_MESSAGING_SERVICE = "ALLOW_SCHEDULE_MESSAGING_SERVICE",
	ALLOW_TEL_ME_MESSAGING_SERVICE = "ALLOW_TEL_ME_MESSAGING_SERVICE",
	ALLOW_VIEW_INCIDENT_SMS = "ALLOW_VIEW_INCIDENT_SMS",
	APPROVE_UNIFORM_ORDER = "APPROVE_UNIFORM_ORDER",
	AUTO_CREATE = "AUTO_CREATE",
	BULK_ACCEPT_OR_REJECT = "BULK_ACCEPT_OR_REJECT",
	CONTROL_ESCALATION_CHECK = "CONTROL_ESCALATION_CHECK",
	CREATE_UNIFORM_ORDER = "CREATE_UNIFORM_ORDER",
	CROSS_BRANCH_ALLOCATE = "CROSS_BRANCH_ALLOCATE",
	EMPLOYEE_PAY_EXPORT = "EMPLOYEE_PAY_EXPORT",
	EVENT_ALLOCATE = "EVENT_ALLOCATE",
	EVENT_APPROVE = "EVENT_APPROVE",
	EVENT_CHANGE_TIMES = "EVENT_CHANGE_TIMES",
	EVENT_CONFIRM = "EVENT_CONFIRM",
	EVENT_CREATE = "EVENT_CREATE",
	EVENT_DELETE = "EVENT_DELETE",
	EVENT_FINISH = "EVENT_FINISH",
	EVENT_INFO = "EVENT_INFO",
	EVENT_INFO_BY_EMPLOYEE = "EVENT_INFO_BY_EMPLOYEE",
	EVENT_MOVE = "EVENT_MOVE",
	EVENT_TAKE_CALL = "EVENT_TAKE_CALL",
	FORCE_LOG_OFF_USERS = "FORCE_LOG_OFF_USERS",
	HR_LISTS = "HR_LISTS",
	INCIDENT_CREATE = "INCIDENT_CREATE",
	INCIDENT_VIEW = "INCIDENT_VIEW",
	PASSWORD_CONFIGURATION = "PASSWORD_CONFIGURATION",
	PATROL_TAG_MANAGEMENT = "PATROL_TAG_MANAGEMENT",
	RECURRING_EVENT_CREATE = "RECURRING_EVENT_CREATE",
	SEND_MESSAGE = "SEND_MESSAGE",
	STOP_SCHEDULING_VIEW_BY_EMPLOYEE = "STOP_SCHEDULING_VIEW_BY_EMPLOYEE",
	TEL_ME = "TEL_ME",
	UPDATE_ADMINISTRATION = "UPDATE_ADMINISTRATION",
	UPDATE_EASY_PAYBILL = "UPDATE_EASY_PAYBILL",
	UPDATE_EMPLOYEE_ADDITIONAL_DETAILS = "UPDATE_EMPLOYEE_ADDITIONAL_DETAILS",
	UPDATE_EMPLOYEE_DETAILS = "UPDATE_EMPLOYEE_DETAILS",
	UPDATE_EMPLOYEE_EQUIPMENT = "UPDATE_EMPLOYEE_EQUIPMENT",
	UPDATE_EMPLOYEE_HOLIDAY = "UPDATE_EMPLOYEE_HOLIDAY",
	UPDATE_EMPLOYEE_HR = "UPDATE_EMPLOYEE_HR",
	UPDATE_EMPLOYEE_LOCATIONS = "UPDATE_EMPLOYEE_LOCATIONS",
	UPDATE_EMPLOYEE_QUALIFICATIONS = "UPDATE_EMPLOYEE_QUALIFICATIONS",
	UPDATE_EMPLOYEE_SICKNESS = "UPDATE_EMPLOYEE_SICKNESS",
	UPDATE_EMPLOYEE_TERMS = "UPDATE_EMPLOYEE_TERMS",
	UPDATE_ESCALATION_MODE = "UPDATE_ESCALATION_MODE",
	UPDATE_LOCATION_CONTRACTS = "UPDATE_LOCATION_CONTRACTS",
	UPDATE_LOCATION_DETAILS = "UPDATE_LOCATION_DETAILS",
	UPDATE_LOCATION_ESCALATION = "UPDATE_LOCATION_ESCALATION",
	UPDATE_LOCATION_NOTIFICATION = "UPDATE_LOCATION_NOTIFICATION",
	UPDATE_LOCATION_POSTS = "UPDATE_LOCATION_POSTS",
	UPDATE_LOCATION_QUALIFICATIONS = "UPDATE_LOCATION_QUALIFICATIONS",
	UPDATE_LOCATION_TIME_CAPTURE = "UPDATE_LOCATION_TIME_CAPTURE",
	UPDATE_OWN_USER_ACCOUNT = "UPDATE_OWN_USER_ACCOUNT",
	UPDATE_PREMISES_EQUIPMENT = "UPDATE_PREMISES_EQUIPMENT",
	UPDATE_PREMISES_KEY = "UPDATE_PREMISES_KEY",
	UPDATE_SCHEDULE_BY_EMPLOYEE = "UPDATE_SCHEDULE_BY_EMPLOYEE",
	UPDATE_SCHEDULE_BY_FILTER = "UPDATE_SCHEDULE_BY_FILTER",
	UPDATE_SCHEDULE_BY_LOCATION = "UPDATE_SCHEDULE_BY_LOCATION",
	UPDATE_TAG_MONITOR = "UPDATE_TAG_MONITOR",
	UPDATE_WORKMONITOR = "UPDATE_WORKMONITOR",
	VETTING_OUTSOURCE = "VETTING_OUTSOURCE",
	VIEW_ADMINISTRATION = "VIEW_ADMINISTRATION",
	VIEW_REPORTS = "VIEW_REPORTS",
	DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS",
	VIEW_ADMIN_SECURITY = "VIEW_ADMIN_SECURITY",
	VIEW_CALL_TAKER_MONITOR = "VIEW_CALL_TAKER_MONITOR",
	VIEW_EASY_PAYBILL = "VIEW_EASY_PAYBILL",
	VIEW_EMPLOYEE_ADDITIONAL_DETAILS = "VIEW_EMPLOYEE_ADDITIONAL_DETAILS",
	VIEW_EMPLOYEE_DETAILS = "VIEW_EMPLOYEE_DETAILS",
	VIEW_EMPLOYEE_EQUIPMENT = "VIEW_EMPLOYEE_EQUIPMENT",
	VIEW_EMPLOYEE_HOLIDAY = "VIEW_EMPLOYEE_HOLIDAY",
	VIEW_EMPLOYEE_HR = "VIEW_EMPLOYEE_HR",
	VIEW_EMPLOYEE_LOCATIONS = "VIEW_EMPLOYEE_LOCATIONS",
	VIEW_EMPLOYEE_QUALIFICATIONS = "VIEW_EMPLOYEE_QUALIFICATIONS",
	VIEW_EMPLOYEE_SICKNESS = "VIEW_EMPLOYEE_SICKNESS",
	VIEW_EMPLOYEE_TERMS = "VIEW_EMPLOYEE_TERMS",
	VIEW_EVENT_INFO_RATES_TAB = "VIEW_EVENT_INFO_RATES_TAB",
	VIEW_LOCATION_CONTRACTS = "VIEW_LOCATION_CONTRACTS",
	VIEW_LOCATION_DETAILS = "VIEW_LOCATION_DETAILS",
	VIEW_LOCATION_ESCALATION = "VIEW_LOCATION_ESCALATION",
	VIEW_LOCATION_NOTIFICATION = "VIEW_LOCATION_NOTIFICATION",
	VIEW_LOCATION_POSTS = "VIEW_LOCATION_POSTS",
	VIEW_LOCATION_QUALIFICATIONS = "VIEW_LOCATION_QUALIFICATIONS",
	VIEW_LOCATION_TIME_CAPTURE = "VIEW_LOCATION_TIME_CAPTURE",
	VIEW_OWN_USER_ACCOUNT = "VIEW_OWN_USER_ACCOUNT",
	VIEW_POST_PAY_RATES = "VIEW_POST_PAY_RATES",
	VIEW_PREMISES_EQUIPMENT = "VIEW_PREMISES_EQUIPMENT",
	VIEW_PREMISES_KEY = "VIEW_PREMISES_KEY",
	VIEW_SCHEDULE_BY_EMPLOYEE = "VIEW_SCHEDULE_BY_EMPLOYEE",
	VIEW_SCHEDULE_BY_FILTER = "VIEW_SCHEDULE_BY_FILTER",
	VIEW_SCHEDULE_BY_LOCATION = "VIEW_SCHEDULE_BY_LOCATION",
	VIEW_SCHEDULE_REPORTS = "VIEW_SCHEDULE_REPORTS",
	VIEW_TAG_MONITOR = "VIEW_TAG_MONITOR",
	VIEW_UNIFORM_ORDER = "VIEW_UNIFORM_ORDER",
	VIEW_WORKMONITOR = "VIEW_WORKMONITOR",
	CREATE_AND_UPDATE_SCHEDULE = "CREATE_AND_UPDATE_SCHEDULE",
	VIEW_PAYROLL = "VIEW_PAYROLL",
	BYPASS_SIA_VALIDATION = "BYPASS_SIA_VALIDATION",
}
export const IS_SUPER_ADMIN = "IS_SUPER_ADMIN"

export const USER_ACCESS_MAPPING = {
	CREATE_AND_UPDATE_EMPLOYEE: [
		"UPDATE_EMPLOYEE_ADDITIONAL_DETAILS",
		"UPDATE_EMPLOYEE_DETAILS",
		"UPDATE_EMPLOYEE_EQUIPMENT",
		"UPDATE_EMPLOYEE_HOLIDAY",
		"UPDATE_EMPLOYEE_HR",
		"UPDATE_EMPLOYEE_LOCATIONS",
		"UPDATE_EMPLOYEE_QUALIFICATIONS",
		"UPDATE_EMPLOYEE_SICKNESS",
		"UPDATE_EMPLOYEE_TERMS",
	],
	VIEW_EMPLOYEE: [
		"VIEW_EMPLOYEE_ADDITIONAL_DETAILS",
		"VIEW_EMPLOYEE_DETAILS",
		"VIEW_EMPLOYEE_EQUIPMENT",
		"VIEW_EMPLOYEE_HOLIDAY",
		"VIEW_EMPLOYEE_HR",
		"VIEW_EMPLOYEE_LOCATIONS",
		"VIEW_EMPLOYEE_QUALIFICATIONS",
		"VIEW_EMPLOYEE_SICKNESS",
		"VIEW_EMPLOYEE_TERMS",
	],
	CREATE_AND_UPDATE_LOCATION: [
		"UPDATE_LOCATION_CONTRACTS",
		"UPDATE_LOCATION_DETAILS",
		"UPDATE_LOCATION_ESCALATION",
		"UPDATE_LOCATION_NOTIFICATION",
		"UPDATE_LOCATION_POSTS",
		"UPDATE_LOCATION_QUALIFICATIONS",
		"UPDATE_LOCATION_TIME_CAPTURE",
	],
	VIEW_LOCATION: [
		"VIEW_LOCATION_CONTRACTS",
		"VIEW_LOCATION_DETAILS",
		"VIEW_LOCATION_ESCALATION",
		"VIEW_LOCATION_NOTIFICATION",
		"VIEW_LOCATION_POSTS",
		"VIEW_LOCATION_QUALIFICATIONS",
		"VIEW_LOCATION_TIME_CAPTURE",
	],
	CREATE_AND_UPDATE_CUSTOMER: "",
	VIEW_CUSTOMER: "",
	CREATE_AND_UPDATE_SUPPLIER: "",
	VIEW_SUPPLIER: "",
	CREATE_AND_UPDATE_SCHEDULES: ["CREATE_AND_UPDATE_SCHEDULE"],
	VIEW_SCHEDULES: ["VIEW_SCHEDULE_BY_EMPLOYEE", "VIEW_SCHEDULE_BY_FILTER", "VIEW_SCHEDULE_BY_LOCATION"],
	CREATE_AND_UPDATE_WORK_MONITOR: ["UPDATE_WORKMONITOR"],
	VIEW_WORK_MONITOR: ["VIEW_WORKMONITOR"],
	VIEW_ADMIN: ["VIEW_ADMINISTRATION", "VIEW_ADMIN_SECURITY"],
	VIEW_REPORTS: ["VIEW_REPORTS"],
	DOWNLOAD_REPORTS: ["DOWNLOAD_REPORTS"],
	VIEW_PAYROLL: ["VIEW_PAYROLL"],
	BYPASS_SIA_VALIDATION: ["BYPASS_SIA_VALIDATION"],
}

export const permissionsDropdownOptions = Object.values(Permissions).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* PayBill ---> PB Codes
export enum PBCodeOptions {
	BRANCH = "BRANCH",
	CONTRACT = "CONTRACT",
	EVENT_TYPE = "EVENT_TYPE",
	PB_RULE = "PB_RULE",
	POST = "POST",
	PUBLIC_HOLIDAY = "PUBLIC_HOLIDAY",
	QUALIFICATION = "QUALIFICATION",
	TERMS = "TERMS",
}

export const PBCodeOptionsDropdown = Object.values(PBCodeOptions).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* PayBill ---> PB Rules
export enum HoursType {
	NORM = "NORM",
}

export const HoursTypeOptions = Object.values(HoursType).map(item => ({
	label: item,
	value: caseConverters.constantCase(item),
}))

//* PayBill ---> PB Rules
export enum RateType {
	HOURLY = "HOURLY",
	DAILY = "DAILY",
	WEEKLY = "WEEKLY",
	MONTHLY = "MONTHLY",
}
export const RateTypeOptions = Object.values(RateType).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* PayBill ---> PB Rules
export enum RuleType {
	PAY = "PAY",
	BILL = "BILL",
	BOTH = "BOTH",
}

export const RuleTypeOptions = Object.values(RuleType).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))

//* Static Data ---> Contact Details
export enum ContactMethodsEnum {
	EMAIL = "EMAIL",
	PHONE = "PHONE",
}

export const ContactMethodsOptions = Object.values(ContactMethodsEnum).map(item => ({
	label: caseConverters.titleCase(item),
	value: item,
}))
export const SIA_LICENSE_SECTORS = [
	"Key Holding",
	"Security Guarding",
	"Door Supervision",
	"Public Space Surveillance (CCTV)",
	"Cash and Valuables in Transit",
	"Vehicle Immobilisation",
	"Close Protection",
]
export const TABLE_SIA_LICENSE_SECTORS = [
	"Security Guarding",
	"Door Supervision",
	"Public Space Surveillance (CCTV)",
	"Vehicle Immobilisation",
]

//* UK counties

const COUNTIES_UK = [
	"Aberdeen",
	"Aberdeenshire",
	"Anglesey",
	"Angus",
	"Antrim",
	"Ards",
	"Argyll and Bute",
	"Armagh",
	"Ballymena",
	"Ballymoney",
	"Banbridge",
	"Barking and Dagenham",
	"Bath and North East Somerset",
	"Bedfordshire",
	"Belfast",
	"Berkshire",
	"Bexley",
	"Blackburn with Darwen",
	"Blaenau Gwent",
	"Bournemouth",
	"Brent",
	"Bridgend",
	"Brighton and Hove",
	"Bristol",
	"Bromley",
	"Buckinghamshire",
	"Caerphilly",
	"Cambridgeshire",
	"Camden",
	"Cardiff",
	"Carmarthenshire",
	"Carrickfergus",
	"Castlereagh",
	"Ceredigion",
	"Cheshire",
	"Clackmannanshire",
	"Coleraine",
	"Conwy",
	"Cookstown",
	"Cornwall",
	"Craigavon",
	"Croydon",
	"Cumbria",
	"Darlington",
	"Denbighshire",
	"Derby",
	"Derbyshire",
	"Derry",
	"Devon",
	"Dorset",
	"Down",
	"Dumfries and Galloway",
	"Dundee",
	"Dungannon",
	"Durham",
	"Ealing",
	"East Ayrshire",
	"East Dunbartonshire",
	"East Lothian",
	"East Renfrewshire",
	"East Riding of Yorkshire",
	"East Sussex",
	"Edinburgh",
	"Eilean Siar",
	"Enfield",
	"Essex",
	"Falkirk",
	"Fermanagh",
	"Fife",
	"Flintshire",
	"Glasgow",
	"Gloucestershire",
	"Greenwich",
	"Gwynedd",
	"Hackney",
	"Halton",
	"Hammersmith and Fulham",
	"Hampshire",
	"Haringey",
	"Harrow",
	"Hartlepool",
	"Havering",
	"Herefordshire",
	"Hertfordshire",
	"Highland",
	"Hillingdon",
	"Hounslow",
	"Inverclyde",
	"Isle of Wight",
	"Islington",
	"Kensington and Chelsea",
	"Kent",
	"Kingston upon Hull",
	"Kingston upon Thames",
	"Lambeth",
	"Lancashire",
	"Larne",
	"Leicester",
	"Leicestershire",
	"Lewisham",
	"Limavady",
	"Lincolnshire",
	"Lisburn",
	"London",
	"Luton",
	"Magherafelt",
	"Manchester",
	"Medway",
	"Merseyside",
	"Merthyr Tydfil",
	"Merton",
	"Middlesbrough",
	"Midlothian",
	"Milton Keynes",
	"Monmouthshire",
	"Moray",
	"Moyle",
	"Neath Port Talbot",
	"Newham",
	"Newport",
	"Newry and Mourne",
	"Newtownabbey",
	"Norfolk",
	"North Ayshire",
	"North Down",
	"North East Lincolnshire",
	"North Lanarkshire",
	"North Lincolnshire",
	"North Somerset",
	"North Yorkshire",
	"Northamptonshire",
	"Northumberland",
	"Nottingham",
	"Nottinghamshire",
	"Omagh",
	"Orkney Islands",
	"Oxfordshire",
	"Pembrokeshire",
	"Perthshire and Kinross",
	"Peterborough",
	"Plymouth",
	"Poole",
	"Portsmouth",
	"Powys",
	"Redbridge",
	"Redcar and Cleveland",
	"Renfrewshire",
	"Rhondda, Cynon, Taff",
	"Richmond upon Thames",
	"Rutland",
	"Scottish Borders",
	"Shetland Islands",
	"Shropshire",
	"Somerset",
	"South Ayrshire",
	"South Gloucestershire",
	"South Lanarkshire",
	"South Yorkshire",
	"Southampton",
	"Southend-on-Sea",
	"Southwark",
	"Staffordshire",
	"Stirling",
	"Stockton-on-Tees",
	"Stoke-on-Trent",
	"Strabane",
	"Suffolk",
	"Surrey",
	"Sutton",
	"Swansea",
	"Swindon",
	"Telford and Wrekin",
	"Thurrock",
	"Torbay",
	"Torfaen",
	"Tower Hamlets",
	"Tyne and Wear",
	"Vale of Glamorgan",
	"Waltham Forest",
	"Wandsworth",
	"Warrington",
	"Warwickshire",
	"West Dunbartonshire",
	"West Lothian",
	"West Midlands",
	"West Sussex",
	"West Yorkshire",
	"Westminster",
	"Wiltshire",
	"Worcestershire",
	"Wrexham",
	"York",
]

export const countiesOptions = COUNTIES_UK.map(item => ({ label: item, value: caseConverters.constantCase(item) }))

export const POSTAL_CODE_LENGTH = 6

export const defaultGeocodeData = {
	results: [
		{
			address_components: [
				{
					long_name: "",
					short_name: "",
					types: [""],
				},
				{
					long_name: "",
					short_name: "",
					types: [""],
				},
				{
					long_name: "",
					short_name: "",
					types: [""],
				},
				{
					long_name: "",
					short_name: "",
					types: [""],
				},
				{
					long_name: "",
					short_name: "",
					types: [""],
				},
			],
			formatted_address: "",
			geometry: {
				bounds: {
					northeast: {
						lat: 0,
						lng: 0,
					},
					southwest: {
						lat: 0,
						lng: 0,
					},
				},
				location: {
					lat: 53.483959,
					lng: -2.244644,
				},
				location_type: "",
				viewport: {
					northeast: {
						lat: 0,
						lng: 0,
					},
					southwest: {
						lat: 0,
						lng: 0,
					},
				},
			},
			place_id: "",
			postcode_localities: [""],
			types: [""],
		},
	],
	status: "",
}

export const defaultDistanceMatrixResponseData = {
	destination_addresses: [""],
	origin_addresses: [""],
	rows: [
		{
			elements: [
				{
					distance: {
						text: "",
						value: 0,
					},
					duration: {
						text: "",
						value: 0,
					},
					status: "",
				},
			],
		},
	],
	status: "",
}

export enum platformTypeENUM {
	"WEB" = "WEB",
	"MOBILE" = "MOBILE",
}

export const WORK_MONITOR_REFRESH_TIME = 1 * 60 * 1000
export const WORK_MONITOR_UPDATE_TIME = 1 * 60 * 1000
export const Ongoing = "Ongoing"

export enum qualificationState {
	Approved = "Approved",
	Pending = "Pending",
}

export const bookedOn = "Booked On"

export enum WorkMonitorAction {
	PENDING = "PENDING",
	CHASED = "CHASED",
	BOOKON = "BOOKON",
	BOOKOFF = "BOOKOFF",
}

export const pageStyleForQR = `
@media print {
	#para {
		font-weight:700;
		font-size:22px;
		}
 }
 @media print {
	#para2 {
		display:block;
		font-weight: 600; 
		text-align: center; 
		font-size:18px;
		}
 }
 @media print {
	#tt {
		display:block;
		font-weight: bold; 
		text-align: center; 
		font-size:18px;
		}
 }
 @media print {
	#title {
		font-weight:bold;
		font-size:32px;
		color: black;
		}
 }
 @media print {
	#locationName {
		font-weight:bold;
		font-size:22px;
		color: black;
		}
 }
 @media print {
	#titleHolder {
		display:block;
		padding-bottom:10px;
		}
 }
 @media print {
	#qrList{
		display: grid;
		  grid-template-columns: repeat(3, 1fr);
		  column-gap: 0.5em;
		  row-gap: 0.75em;
		page-break-inside: auto;
	}
}
@media print {
	#qr{
		width:130px;
		height:130px;
		display:block;
		margin:auto;
	}
}
@media print {
	#qrSpan{
		padding: 0.5rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		border-width: 0.125rem;
		border-radius: 0.125rem;
		flex-direction: column;
		justify-content: center; 
		align-items: center; 
  		border-radius: 4px;
		border-color:grey;
		page-break-inside:avoid;
	}
}

@page {
	size: auto;
	margin:10%;
	
}
`

export const getWeekDayIndex: { [key: string]: number } = {
	Monday: 0,
	Tuesday: 1,
	Wednesday: 2,
	Thursday: 3,
	Friday: 4,
	Saturday: 5,
	Sunday: 6,
}

export const defaultMapViewOptions = [
	{
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		featureType: "administrative.land_parcel",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#bdbdbd",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry",
		stylers: [
			{
				color: "#dadada",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "transit.line",
		elementType: "geometry",
		stylers: [
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "transit.station",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#c9c9c9",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
]
