import { Select, SelectProps } from "antd-v5"
import { forwardRef } from "react"
import InputLabel from "./InputLabel"
import { twMerge } from "tailwind-merge"
import Icon from "./Icon"

export interface AntSelectProps extends SelectProps {
	label?: string
	containerClass?: string
}

const AntdSelect = forwardRef<SelectProps, AntSelectProps>(
	({ containerClass = "w-full", label = "", ...rest }, ref) => {
		return (
			<div className={twMerge(`flex flex-col`, containerClass)}>
				<InputLabel label={label || ""} />
				<Select
					showSearch
					filterOption={(input, option) =>
						((option?.label || "") as string).toLowerCase().includes(input.toLowerCase())
					}
					{...rest}
					ref={ref as any}
					suffixIcon={
						rest.suffixIcon ? (
							rest.suffixIcon
						) : (
							<Icon color="secondary-light" size={20} name="dropdownArrow" />
						)
					}
				/>
			</div>
		)
	},
)

export default AntdSelect
