import React, { useMemo, useState } from "react"
import PBCodes from "./PBCodes"
// import PBRules from "./PBRules"
// import PublicHolidays from "./PublicHoliday"
import PBTerms from "./PBTerms"
import { useAbac } from "react-abac"
import { Divider, Segmented } from "antd-v5"

const Index = () => {
	const { userHasPermissions } = useAbac()
	const [value, setValue] = useState<string>("PB Codes")
	const options = useMemo(() => {
		return [
			{
				label: "PB Codes",
				perform: [],
				component: <PBCodes />,
			},
			// {
			// 	label: "PB Rules",
			// 	perform:[],
			// 	component:<PBRules />,
			// },
			{
				label: "Terms",
				perform: [],
				component: <PBTerms />,
			},
			// {
			// 	label: "Public Holidays",
			// 	perform:[],
			// 	component:<PublicHolidays />,
			// },
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform))
	}, [])
	return (
		<div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="flex items-center justify-start">
				<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="px-2">{options?.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
