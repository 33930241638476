import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { Provider } from "react-redux"
import store from "store"
import { AuthLayout, AbacLayout } from "components"
import {
	Employees,
	Locations,
	Schedules,
	WorkMonitor,
	Admin,
	// Organization,
	PayBill,
	StaticData,
	Reports,
	Dashboard,
	// Accounts,
} from "pages"
import { AppRoutes } from "config"
import { Slide, ToastContainer } from "react-toastify"
import NotFound from "pages/404/404"
import { ConfigProvider } from "antd"
import { ConfigProvider as ConfigProviderV5 } from "antd-v5"
//? To set AntD Date Picker from Monday to Sunday
import en_GB from "antd/lib/locale-provider/en_GB"
import "moment/locale/en-gb"
import AutoReLogin from "parentComponents/AutoReLogin"
import InactivityTimerWrapper from "parentComponents/InactivityTimer"
import JafWrapper from "pages/jaf/JafWrapper"
import { AntdThemeConfig } from "config"

const LoginPage = React.lazy(() => import("pages/auth/Login"))
const JafPage = React.lazy(() => import("pages/jaf/Jaf"))
function App() {
	return (
		<main className="font-primary">
			<ToastContainer
				transition={Slide}
				position="top-right"
				theme="colored"
				rtl={false}
				autoClose={3000}
				pauseOnHover={false}
				pauseOnFocusLoss={false}
				hideProgressBar
				draggable
				newestOnTop
				closeOnClick
			/>

			<Provider store={store}>
				<ConfigProviderV5 prefixCls="ant5" theme={AntdThemeConfig}>
					<ConfigProvider locale={en_GB}>
						<AutoReLogin />
						<BrowserRouter>
							<InactivityTimerWrapper />
							<Routes>
								<Route path="/" element={<Navigate to={AppRoutes.SCHEDULES_PAGE} />} />
								<Route path={AppRoutes.SCHEDULES_PAGE} element={<AbacLayout />}>
									<Route index element={<Schedules />} />
									<Route path={AppRoutes.LOCATION} element={<Locations />} />
									<Route path={AppRoutes.EMPLOYEES} element={<Employees />} />
									<Route path={AppRoutes.WORK_MONITOR} element={<WorkMonitor />} />
									<Route path={AppRoutes.JAF} element={<JafWrapper />} />
									{/* <Route path={AppRoutes.ACCOUNTS} element={<Accounts />} /> */}
									<Route path={AppRoutes.DASHBOARD} element={<Dashboard />}></Route>
								</Route>
								<Route path="/admin" element={<AbacLayout />}>
									<Route index element={<Admin />} />
									{/* <Route path={AppRoutes.ORGANIZATION} element={<Organization />} /> */}
									<Route path={AppRoutes.PAY_BILL} element={<PayBill />} />
									<Route path={AppRoutes.STATIC_DATA} element={<StaticData />} />
								</Route>
								<Route path="/reports" element={<AbacLayout />}>
									<Route index element={<Reports />} />
								</Route>
								<Route path="auth" element={<AuthLayout />}>
									<Route path="login" element={<LoginPage />} />
								</Route>
								<Route path={AppRoutes.JAF} element={<JafPage />} />
								<Route path="*" element={<NotFound />}></Route>
							</Routes>
						</BrowserRouter>
					</ConfigProvider>
				</ConfigProviderV5>
			</Provider>
		</main>
	)
}

export default App
