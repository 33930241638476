import WorkMonitorView from "pages/workMonitor/WorkMonitorView"
import { ReportShiftInterface, ShiftInterface } from "@type/workMonitor.types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import EmployeeInfo from "./EmployeeInfo"
import LocationInfo from "./LocationInfo"
import { CSVLink } from "react-csv"
import Icon from "components/Icon"
import { DATE_FORMATS, ShiftStatus } from "config/constants"
// import Button from "components/Button"
import { useReactToPrint } from "react-to-print"
import { convertIOSDateToHTMLFormat, sortByProperty } from "config/utils"
import WorkMonitorShifts from "services/workMonitor/workMonitor.service"
import logo from "images/timeTrailLogo.png"
import moment from "moment"
import IncidentView from "./IncidentInfo"
import { pageStyle } from "./constants"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { Card, Divider, Radio } from "antd-v5"
import { useHeightResize } from "hooks"
import { AntdButton } from "components"
import { CloudDownloadOutlined } from "@ant-design/icons"

interface Props {
	employeeId: string
	locationId: string
	scheduleId: string
	status: string
}
const headers = [
	{ label: "Officer Name", key: "employee" },
	{ label: "Site Name", key: "location" },
	{ label: "Date", key: "date" },
	{ label: "Event Type", key: "status" },
	{ label: "Event Time", key: "eventTime" },
	{ label: "QR Name", key: "name" },
	{ label: "Location", key: "latLng" },
	{ label: "Address", key: "" },
	{ label: "Comments", key: "comment" },
]

enum ScheduleInfoTabs {
	"Employee" = "Employee",
	"Location" = "Location",
	"Activity" = "Activity",
}

const radioItems = Object.keys(ScheduleInfoTabs)

const ScheduleInfo: React.FC<Props> = ({ employeeId, locationId, scheduleId, status }) => {
	useHeightResize({ element: "schedule-info-body" })
	const [shift, setShift] = useState<ShiftInterface>()
	const [loading, setLoading] = useState(false)
	const [activeRadio, setActiveRadio] = useState(radioItems[0])
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
	})

	const fetchShift = async () => {
		setLoading(true)
		const shifts = await WorkMonitorShifts.getScheduleShiftEvents(scheduleId)
		setShift({ ...shifts.data[0] })
		setLoading(false)
	}
	useEffect(() => {
		if (status !== ScheduleStatus.INACTIVE) fetchShift()
		else setShift(undefined)
	}, [scheduleId])

	const shiftEventsData = useMemo(() => {
		const data: any[] = []
		if (!shift?.shiftEvents) return []
		shift?.shiftEvents
			.filter(item => !item.isEventMissed)
			.forEach(({ createdAt, geo, eventTime, status, ...shiftEvent }) => {
				if (status === "QR") {
					shiftEvent.scannedQREvents?.forEach(
						({
							createdAt: createdAtQR,
							eventTime: eventTimeQR,
							geo: geoQR,
							status: statusQR,
							name,
							...shiftEvent
						}) => {
							data.push({
								...shiftEvent,
								employee: shift.employee,
								eventTime: convertIOSDateToHTMLFormat(
									eventTimeQR || "",
									DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm,
								),
								date: convertIOSDateToHTMLFormat(createdAtQR, DATE_FORMATS.REPORT_DD_MM_YYYY),
								latLng: `${geoQR?.coordinates[0].toFixed(4) || ""} , ${
									geoQR?.coordinates[1].toFixed(4) || ""
								}`,
								location: shift?.location,
								status: statusQR,
								name,
							})
						},
					)
				} else {
					data.push({
						...shiftEvent,
						eventTime: convertIOSDateToHTMLFormat(eventTime || "", DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm),
						employee: shift.employee,
						date: convertIOSDateToHTMLFormat(createdAt || "", DATE_FORMATS.REPORT_DD_MM_YYYY),
						latLng: geo?.coordinates.length
							? `${geo?.coordinates[0].toFixed(4) || ""} , ${geo?.coordinates[1].toFixed(4) || ""}`
							: "",
						location: shift?.location || "",
						status,
					})
				}
			})
		return data.sort(sortByProperty("eventTime"))
	}, [shift, loading, scheduleId])

	if (!employeeId && !locationId) return <></>
	return (
		<div className="flex flex-col space-y-4">
			<Radio.Group
				className="w-full"
				buttonStyle="outline"
				optionType="button"
				value={activeRadio}
				onChange={e => setActiveRadio(e.target.value)}
			>
				<div className="grid grid-cols-3">
					{radioItems.map(item => (
						<Radio
							key={item}
							className="text-center"
							disabled={item === ScheduleInfoTabs.Employee && !employeeId}
							value={item}
						>
							{item}
						</Radio>
					))}
				</div>
			</Radio.Group>
			<div id="schedule-info-body" className="overflow-y-auto">
				{activeRadio === ScheduleInfoTabs.Employee && <EmployeeInfo id={employeeId} />}
				{activeRadio === ScheduleInfoTabs.Location && <LocationInfo id={locationId} />}
				{activeRadio === ScheduleInfoTabs.Activity &&
					(loading ? (
						<div className="flex h-88 w-full items-center justify-center">
							<Icon name="spinner" color="white" size={80} className="mr-3 animate-spin !text-purple" />
						</div>
					) : (
						<Card className="min-h-80" size="small">
							{!shift?.incidents?.length && !shift?.shiftEvents?.length && (
								<div className="min-h-full items-center text-center text-lg font-semibold">No Data</div>
							)}
							{!!shift?.incidents?.length && <IncidentView incidents={shift?.incidents ?? []} />}
							<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
							{!!(shift?.shiftEvents?.filter(events => events.status !== ShiftStatus.CHASED) || [])
								.length && (
								<>
									<div className="flex flex-col items-center justify-center gap-2 2xl:flex-row">
										<CSVLink
											data={shiftEventsData}
											headers={headers}
											filename={`Report-${shift?.employee}-${shift?.post}`}
										>
											<AntdButton
												className="flex items-center justify-center"
												icon={<CloudDownloadOutlined />}
											>
												Generate CSV
											</AntdButton>
											{/* <div className="ml-1 text-white">Generate CSV</div> */}
											{/* <Icon name="download" size={22} color="white" className="ml-2 "></Icon> */}
										</CSVLink>

										<AntdButton
											className="flex items-center justify-center"
											icon={<CloudDownloadOutlined />}
											onClick={handlePrint}
										>
											Generate PDF
										</AntdButton>
									</div>

									<WorkMonitorView shift={shift} containerClass="h-auto mt-2 border-none" />
								</>
							)}
						</Card>
					))}
			</div>
			{/* <Tabs initialTab={employeeId ? 0 : 1}>
				<Tab title="Employee" perform={[]} hasHeader disabled={!employeeId}>
					<EmployeeInfo id={employeeId} />
				</Tab>
				<Tab title="Location" perform={[]} hasHeader>
					<LocationInfo id={locationId} />
				</Tab>
				<Tab title="Activity" perform={[]} hasHeader shouldOverflow={false}>
					{loading ? (
						<div className="flex h-88 w-full items-center justify-center">
							<Icon name="spinner" color="white" size={80} className="mr-3 animate-spin !text-purple" />
						</div>
					) : (
						<div className="flex min-h-88 flex-col">
							{!shift?.incidents?.length && !shift?.shiftEvents?.length && (
								<div className="flex h-88 items-center justify-center py-5">
									<span className="text-xl font-semibold">No Data</span>
								</div>
							)}
							{!!shift?.incidents?.length && <IncidentView incidents={shift?.incidents ?? []} />}
							{!!(shift?.shiftEvents?.filter(events => events.status !== ShiftStatus.CHASED) || [])
								.length && (
								<>
									<div className="flex flex-col items-center justify-center 2xl:flex-row">
										<div className="my-3 ml-2 flex w-fit items-center rounded-xl bg-black ">
											<CSVLink
												data={shiftEventsData}
												headers={headers}
												className=" flex space-x-2 p-1"
												filename={`Report-${shift?.employee}-${shift?.post}`}
											>
												<div className="ml-1 text-white">Generate CSV</div>
												<Icon name="download" size={22} color="white" className="ml-2 "></Icon>
											</CSVLink>
										</div>
										<Button
											className="my-3 ml-2 flex w-auto items-center rounded-xl bg-black px-0 py-0 "
											onClick={handlePrint}
										>
											<div className=" flex space-x-2 p-1">
												<div className="ml-1 text-white">Generate PDF</div>
												<Icon name="download" size={22} color="white" className="ml-2 "></Icon>
											</div>
										</Button>
									</div>

									<WorkMonitorView shift={shift} containerClass="h-auto mt-2 border-none" />
								</>
							)}
						</div>
					)}
				</Tab>
			</Tabs> */}
			<div ref={componentRef}>
				<ShiftEventPrint
					data={shiftEventsData}
					time={{ startTime: shift?.startTime, finishTime: shift?.finishTime }}
				/>
			</div>
		</div>
	)
}

interface ShiftEventProps {
	data: ReportShiftInterface[]
	time: { startTime?: string; finishTime?: string }
}

const ShiftEventPrint: React.FC<ShiftEventProps> = ({ data, time }) => {
	const Headers = {
		status: "Status",
		latLng: "Coordinates",
		eventTime: "Event Time",
		dueTime: "Due Time",
		name: "QR Tag",
	}
	return (
		<>
			<div className="grid grid-cols-8">
				<div id="title" className="col-span-2 hidden text-left">
					{data[1]?.employee}
					<br />
					{moment(time.startTime).format(DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm) +
						" to " +
						moment(time.finishTime).format(DATE_FORMATS.HH_mm)}
				</div>

				<div id="reportName" className="col-span-4 hidden text-center text-md">
					Shift Event Report for {data[1]?.location}
				</div>

				<img
					id="logo"
					src={logo}
					alt="TimeTrail-Login"
					className="col-span-2 hidden h-20  w-auto object-cover"
				/>
			</div>
			<div id="parent" className="hidden  w-full flex-col justify-center">
				{[Headers, ...data]
					.filter(event => event.status !== "CHASED")
					.sort((a, b) => new Date(a.dueTime).valueOf() - new Date(b.dueTime).valueOf())
					.map((entity, index) => {
						return (
							<div
								id="row"
								key={index}
								className="grid grid-cols-12 space-x-2 border-b border-b-gray-300 py-0.25"
							>
								<div className="col-span-1">{entity.status}</div>
								<div className="col-span-3">{entity.latLng ?? "N/A"}</div>
								<div className="col-span-3">{entity.eventTime}</div>
								<div className="col-span-2">
									{moment(entity.dueTime).isValid()
										? moment(entity.dueTime).format("HH:mm")
										: entity.dueTime}
								</div>
								<div className="col-span-3">{entity.name ?? "N/A"}</div>
							</div>
						)
					})}
			</div>
		</>
	)
}

export default ScheduleInfo
