import { ShiftInterface, WorkMonitorTableHeaderInterface } from "@type/workMonitor.types"
import WorkMonitorTable from "./WorkMonitorTable"
import { Tab, Tabs } from "components/Tabs"
import { twMerge } from "tailwind-merge"
import { DATE_FORMATS, ShiftStatus } from "config/constants"
import { getMomentZInstanceOfDate } from "config/utils"
import { WorkMonitorShiftsClassified } from "store/workMonitor/workMonitorManager"
import Icon from "components/Icon"
import useAppSelector from "hooks/useAppSelector"
import { selectWorkMonitorNextLoading } from "store/workMonitor/workMonitor.slice"

const getBeforeAfter = (before: Date, after: Date) => {
	return (
		<div className="flex space-x-4">
			<span className="font-semibold text-green-900">
				{getMomentZInstanceOfDate(before).format(DATE_FORMATS.HH_mm)}
			</span>
			<span className="font-semibold text-red-900">
				{getMomentZInstanceOfDate(after).format(DATE_FORMATS.HH_mm)}
			</span>
		</div>
	)
}

const renderStringForTable = ({ row, key }: any) => row?.[key] || "N/A"
const commonTableHeaders: WorkMonitorTableHeaderInterface[] = [
	{ label: "Location", key: "location" },
	{ label: "Customer", key: "customer", render: renderStringForTable },
	{ label: "Branch", key: "locationBranchName", render: renderStringForTable },
	{ label: "Post", key: "post" },
	{
		label: "Employee",
		key: "employee",
		classNameFunc: ({ row, key }: any) => (!row?.[key] ? `border-b-gray-600 text-dark` : ""),
		render: ({ row, key }: any) => row?.[key] || "UNALLOCATED",
	},
	{
		label: "Code",
		key: "employee",
		render: ({ row }) => row["employeeBranchName"] || row["employeeSupplierName"] || "N/A",
	},
	{ label: "SIN", key: "locationSin", render: renderStringForTable },
	{ label: "PIN", key: "employeePin", render: renderStringForTable },
	{
		label: "Action",
		key: "action",
		className: "font-semibold",
		classNameFunc: ({ row }: any) => {
			return twMerge(
				...Object.entries({
					[`border-b-gray-600 !bg-green-200 text-dark`]: row.isInBefore,
					[`border-b-gray-600 !bg-yellow-200 text-dark`]: row.isInAfter,
					[`border-b-gray-600 text-dark`]: row.isLate,
					// [`animate-blink-red`]: row.isLate && !!row?.employee,
					// [`border-b-gray-600 animate-blink-red text-dark`]: row.isLate,
				}).map(([key, value]) => (value ? key : "")),
			)
		},
		render: ({ row }: any) => {
			return `${row["action"] === ShiftStatus.CHASED ? "CHASE" : row["action"]}`
		},
	},
	{
		label: "Expected Time",
		key: "nextStatusTime",
		render: ({ row, key, rowIndex }: any) => {
			return (
				<div className="flex w-fit space-x-8">
					{getMomentZInstanceOfDate(row[key]?.eventTime)
						.format("DD-MM-YYYYTHH:mm")
						.split("T")
						.map(timePiece => (
							<span key={`${rowIndex}_${key}_${timePiece}`}>{timePiece}</span>
						))}
				</div>
			)
		},
	},
	{
		label: "Window",
		key: "nextStatusTime",
		render: ({ row, key }: any) => {
			if (row[key]?.eventTime) {
				return getBeforeAfter(row[key].window?.before, row[key].window?.after)
			}
		},
	},
]
interface Props {
	selectedRow: ShiftInterface[] | null
	rowClickHandler: (data: any, event: any) => void
	setOpenShiftDetailsDrawer: (val: boolean) => void
	searchString: string
	shifts: WorkMonitorShiftsClassified
}

const WorkMonitorTabs: React.FC<Props> = ({
	selectedRow,
	setOpenShiftDetailsDrawer,
	rowClickHandler,
	searchString,
	shifts,
}) => {
	const nextLoading = useAppSelector(selectWorkMonitorNextLoading())
	const { activeShift, allocatedShift, criticalShift, unAllocatedShift, cleaning } = shifts

	return (
		<>
			<Tabs initialTab={0} containerClass="bg-white">
				<Tab title={`All (${allocatedShift.length})`} hasHeader shouldOverflow={false}>
					{allocatedShift.length ? (
						<div className="mb-2">
							<WorkMonitorTable
								rowClicked={(data, event) => rowClickHandler(data, event)}
								selectedRow={selectedRow}
								searchString={searchString}
								openShiftDetailsDrawer={() => setOpenShiftDetailsDrawer(true)}
								columns={commonTableHeaders}
								rows={allocatedShift}
							/>
						</div>
					) : (
						<div className="flex h-[80vh] items-center justify-center py-5">
							<span className="text-xl font-semibold">No Allocated Shifts Found</span>
						</div>
					)}
				</Tab>
				<Tab title={`Unallocated (${unAllocatedShift.length})`} hasHeader shouldOverflow={false}>
					{unAllocatedShift.length ? (
						<div className="mb-2">
							<WorkMonitorTable
								rowClicked={(data, event) => rowClickHandler(data, event)}
								selectedRow={selectedRow}
								alternateColor={true}
								searchString={searchString}
								openShiftDetailsDrawer={() => setOpenShiftDetailsDrawer(true)}
								columns={commonTableHeaders}
								rows={unAllocatedShift}
							/>
						</div>
					) : (
						<div className="flex h-[80vh] items-center justify-center py-5">
							<span className="text-xl font-semibold">No Unallocated Shifts Found</span>
						</div>
					)}
				</Tab>
				<Tab title={`Critical (${criticalShift.length})`} hasHeader shouldOverflow={false}>
					{criticalShift.length ? (
						<div className="mb-2">
							<WorkMonitorTable
								rowClicked={(data, event) => rowClickHandler(data, event)}
								selectedRow={selectedRow}
								// alternateColor={true}
								searchString={searchString}
								openShiftDetailsDrawer={() => setOpenShiftDetailsDrawer(true)}
								columns={commonTableHeaders}
								rows={criticalShift}
							/>
						</div>
					) : (
						<div className="flex h-[80vh] items-center justify-center py-5">
							<span className="text-xl font-semibold">No Critical Shifts Found</span>
						</div>
					)}
				</Tab>
				<Tab title={`Check Call (${activeShift.length})`} hasHeader shouldOverflow={false}>
					{activeShift.length ? (
						<div className="mb-2">
							<WorkMonitorTable
								rowClicked={(data, event) => rowClickHandler(data, event)}
								selectedRow={selectedRow}
								alternateColor={true}
								searchString={searchString}
								openShiftDetailsDrawer={() => setOpenShiftDetailsDrawer(true)}
								columns={commonTableHeaders}
								rows={activeShift}
							/>
						</div>
					) : (
						<div className="flex h-[80vh] items-center justify-center py-5">
							<span className="text-xl font-semibold">No Shift has any check call event</span>
						</div>
					)}
				</Tab>
				<Tab title={`Cleaning (${cleaning.length})`} hasHeader shouldOverflow={false}>
					{cleaning.length ? (
						<div className="mb-2">
							<WorkMonitorTable
								rowClicked={(data, event) => rowClickHandler(data, event)}
								selectedRow={selectedRow}
								alternateColor={true}
								searchString={searchString}
								openShiftDetailsDrawer={() => setOpenShiftDetailsDrawer(true)}
								columns={commonTableHeaders}
								rows={cleaning}
							/>
						</div>
					) : (
						<div className="flex h-[80vh] items-center justify-center py-5">
							<span className="text-xl font-semibold">No Shift has any check call event</span>
						</div>
					)}
				</Tab>
			</Tabs>
			{nextLoading && (
				<div className="flex h-fit w-full items-center justify-center">
					<Icon name="spinner" color="dark" size={70} className="animate-spin" />
				</div>
			)}
		</>
	)
}

export default WorkMonitorTabs
