import React, { useEffect, useMemo, useState } from "react"
import Customers from "./Customers"
import SDQualifications from "./SDQualifications"
import EquipmentTypes from "./EquipmentTypes"
// import SDDocumentTypes from "./SDDocumentTypes"
import SDCounties from "./SDCounties"
import SDSuppliers from "./SDSupplier"
import SDPayGroups from "./SDPayGroups"
// import SDBillGroup from "./SDBillGroups"
import SDIncidents from "./SDIncidents"
import { Branch } from "pages"
import SDCompanies from "./SDCompanies"
import { getSDCompanies } from "store/StaticData/SDCompanies.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { IS_SUPER_ADMIN } from "config/constants"
import { useAbac } from "react-abac"
import { Divider, Segmented } from "antd-v5"
// import SDEventTypes from "./SDEventTypes"

const Index = () => {
	const dispatch = useAppDispatch()
	const { userHasPermissions } = useAbac()
	const [value, setValue] = useState<string>("Branches")
	const options = useMemo(() => {
		return [
			{ label: "Branches", component: <Branch />, perform: [] },
			{ label: "Customers", component: <Customers />, perform: [] },
			{ label: "Suppliers", component: <SDSuppliers />, perform: [] },
			{ label: "Companies", component: <SDCompanies />, perform: IS_SUPER_ADMIN },
			{ label: "Qualifications", component: <SDQualifications />, perform: [] },
			// {label:"Event Types",  component: <SDEventTypes />, perform:[]},
			{ label: "Equipment Types", component: <EquipmentTypes />, perform: [] },
			// { label: "Reasons", component: <></>, perform: [] },
			{ label: "Counties", component: <SDCounties />, perform: [] },
			// { label: "CT Messages", component: <></>, perform: [] },
			{ label: "Incidents", component: <SDIncidents />, perform: [] },
			{ label: "Pay Groups", component: <SDPayGroups />, perform: [] },
			// {label:"Bill Groups",  component: <SDBillGroup />, perform:[]},
			// { label: "HR Lists", component: <></>, perform: [] },
			// { label: "Document Types", component: <SDDocumentTypes /> , perform:[]},
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform))
	}, [])
	useEffect(() => {
		dispatch(getSDCompanies())
	}, [])
	return (
		<div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="flex items-center justify-start">
				<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="px-2">{options?.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
