// REACT PACKAGE
import { EmployeeTypesEnum } from "config/constants"
import * as Yup from "yup"

export const postInitialSchema = {
	contract: "",
	PBCode: "",
	shortName: "",
	name: "",
	startTime: "06:00",
	finishTime: "18:00",
	telephone1: "",
	telephone2: "",
	telephone3: "",
	displayOrder: "",
	reportCode: "",
	usedForJobs: true,
	siteTrainingRequired: false,
	enabled: true,
	cliBehavior: "Accept",
	geoFencingEnabled: false,
	type: EmployeeTypesEnum.SECURITY,
	fullAddress: "",
	radius: "",
	latitude: "",
	longitude: "",
	locationAndInstructions: "",
	officerPayRate: "",
	clientChargeRate: "",
}
export const postValidationSchema = Yup.object().shape({
	contract: Yup.string().required().label("Contract Id"),
	PBCode: Yup.string().nullable().label("PBCode Id"),
	shortName: Yup.string().required().label("Short Name"),
	name: Yup.string().required().label("Name"),
	startTime: Yup.string().required().label("Start Time"),
	finishTime: Yup.string().required().label("Finish Time"),
	telephone1: Yup.string().label("Telephone 1"),
	telephone2: Yup.string().label("Telephone 2"),
	telephone3: Yup.string().label("Telephone 3"),
	displayOrder: Yup.number()
		.label("Display Order")
		.nullable(true)
		.transform((_, val) => (val ? Number(val) : null)),
	// reportCode: Yup.string().label("Report Card"),
	// usedForJobs: Yup.boolean().required().label("Used for Job"),
	siteTrainingRequired: Yup.boolean().required().label("Site Training Required"),
	// enabled: Yup.boolean().required().label("Enabled"),
	// cliBehavior: Yup.string().required().label("Cli Behavior"),
	geoFencingEnabled: Yup.boolean().required().label("Cli Behavior"),
	fullAddress: Yup.string().label("Full Address"),
	radius: Yup.number()
		.positive()
		.integer()
		.min(50)
		.max(9999)
		.nullable(true)
		.label("Radius")
		.transform((_, val) => (val ? Number(val) : null)),
	latitude: Yup.string().label("Latitude"),
	longitude: Yup.string().label("Longitude"),
	locationAndInstructions: Yup.string().label("Location and Instructions"),
	clientChargeRate: Yup.number()
		.positive()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Client Charge Rate"),
	officerPayRate: Yup.number()
		.positive()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Officer Pay Rate"),
	type: Yup.string().required().label("Type"),
})
