import React, { useMemo, useState } from "react"
import Modal from "./Modal"
import { Tabs, Tab, AntdButton, AntdInput } from "components" // Icon,
import { employeesService } from "services/index"
import LocationsService from "services/locations/locationDetails.service"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { globalSearchInitialValues, globalSearchValidationSchema } from "Schema/Common/globalSearchSchema"
import GlobalSearchDataModal from "pages/workMonitor/GlobalSearchDataModal"
import { reverseMapEnum } from "config/utils"
import { omitBy } from "lodash"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
}
interface DetailsInterface {
	shortName?: string
	clientPin?: string
	pin?: string
	reference?: string
	sin?: string
	name?: string
}

enum EmployeeFields {
	"Short Name" = "shortName",
	"Client PIN" = "clientPin",
	"PIN" = "pin",
	"SIA Number" = "reference",
}

const EmployeeFieldsValueToKeyMap = reverseMapEnum(EmployeeFields)

enum LocationFields {
	"Location Name" = "name",
	"SIN" = "sin",
}
const LocationFieldsValueToKeyMap = reverseMapEnum(LocationFields)

const GlobalSearchModal: React.FC<Props> = ({ open, modalHandler }) => {
	const [loader, setLoader] = useState(false)
	const [response, setResponse] = useState<{ source: string; data: any } | null>(null)
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const methods = useForm<DetailsInterface>({
		resolver: yupResolver(globalSearchValidationSchema),
	})
	const {
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = methods
	const employeeSearchHandle = async (formData: DetailsInterface) => {
		setLoader(true)
		try {
			const { data: responseData } = await employeesService.getEmployeeBySearch(omitBy(formData, v => !v))
			setResponse({ source: "employee", data: responseData ?? null })
		} catch (error) {
			console.error(error)
		}
		setLoader(false)
	}

	const locationSearchHandle = async (formData: DetailsInterface) => {
		setLoader(true)
		try {
			const { data: responseData } = await LocationsService.getLocationBySearch(omitBy(formData, v => !v))
			setResponse({ source: "location", data: responseData ?? null })
		} catch (error) {
			console.error(error)
		}
		setLoader(false)
	}

	const formData = useMemo(() => {
		return watch()
	}, [watch()])

	const searchQuery = useMemo(() => {
		return Object.fromEntries(
			Object.entries(formData).map(([key, value]: any) => [
				selectedTab === 0 ? EmployeeFieldsValueToKeyMap[key] : LocationFieldsValueToKeyMap[key],
				value,
			]),
		)
	}, [formData])
	return (
		<>
			<Modal
				open={open}
				modalHandler={close => {
					reset({ ...globalSearchInitialValues })
					setSelectedTab(0)
					modalHandler(close)
				}}
			>
				<div className="h-[50vh] w-full overflow-clip px-2">
					<Tabs initialTab={selectedTab} selectedTabNumber={setSelectedTab}>
						<Tab title="Employee" hasHeader>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(employeeSearchHandle)}>
									<div className="grid grid-cols-2 gap-x-5 gap-y-3">
										{Object.entries(EmployeeFields).map(([key, value], index) => (
											<div key={key}>
												<AntdInput
													label={key}
													key={index}
													type="text"
													value={formData[value] ?? ""}
													onChange={e => {
														reset({ [value]: e.target.value })
													}}
													className={`mx-1 ${
														!!formData[value] && "border-green-600 bg-green-100 "
													}`}
												/>
												{!!errors?.[value]?.message && (
													<span className={`mt-1 ml-1 text-sm text-danger `}>
														{errors?.[value]?.message}
													</span>
												)}
											</div>
										))}
										<div className="flex justify-start py-3">
											<AntdButton
												disabled={
													!(
														formData.pin ||
														formData.clientPin ||
														formData.shortName ||
														formData.reference
													)
												}
												htmlType="submit"
												loading={loader}
											>
												Search
											</AntdButton>
										</div>
									</div>
								</form>
							</FormProvider>
						</Tab>
						<Tab title="Location" hasHeader>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(locationSearchHandle)}>
									<div className="grid grid-cols-2 gap-x-5 gap-y-3">
										{Object.entries(LocationFields).map(([label, value], index) => (
											<div key={label}>
												<AntdInput
													label={label}
													key={index}
													type="text"
													value={formData[value] ?? ""}
													onChange={e => {
														reset({ [value]: e.target.value })
													}}
													className={`mx-1 ${
														!!formData[value] && "border-green-600 bg-green-100 "
													}`}
												/>
												{!!errors?.[value]?.message && (
													<span className={`mt-1 ml-1 text-sm text-danger `}>
														{errors?.[value]?.message}
													</span>
												)}
											</div>
										))}
									</div>
									<div className="flex justify-start py-3">
										<AntdButton
											disabled={!(formData.name || formData.sin)}
											htmlType="submit"
											loading={loader}
										>
											Search
										</AntdButton>
									</div>
									<div />
								</form>
							</FormProvider>
						</Tab>
					</Tabs>
				</div>
			</Modal>

			<GlobalSearchDataModal
				response={response}
				searchQuery={searchQuery}
				setResponse={close => {
					modalHandler(true)
					setResponse(close)
				}}
			/>
		</>
	)
}

export default GlobalSearchModal
