import React, { useContext, useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppSelector, useAppDispatch } from "hooks"
import { yupResolver } from "@hookform/resolvers/yup"

import { DocumentField, DateField, FormSelectAntd, FormInputAntd, AntdButton } from "components"

import { clearMedia, selectLoadingMedia, selectMediaURL, uploadMedia } from "store/Media/media.slice"
import DocumentContext, { DocumentContextProps } from "./documentContext"
import { selectLocationDocumentSaving, saveLocationDocumentData } from "store/Location/Documents/documents.slice"

import { documentsInitialValues, documentsValidationSchema } from "Schema"
import { LocationDocumentInterface } from "@type/locations.types"
import { selectSelectedLocation } from "store/globalState.slice"
import { LocationDocumentTypes, locationDocumentOptions } from "config/constants"
import { momentZ } from "config/moment"

interface DocumentFormHandle extends LocationDocumentInterface {}

const Form = () => {
	const dispatch = useAppDispatch()

	const [documentName, setDocumentName] = useState<string>("")

	const selected = useAppSelector(selectSelectedLocation)
	const { document, handleRow } = useContext(DocumentContext) as DocumentContextProps

	const [documentURL] = useAppSelector(selectMediaURL())
	const isSaving = useAppSelector(selectLocationDocumentSaving())
	const isDocumentUrlUploading = useAppSelector(selectLoadingMedia())

	const methods = useForm<DocumentFormHandle>({
		resolver: yupResolver(documentsValidationSchema),
	})

	const {
		reset,
		setValue,
		handleSubmit,
		watch,
		clearErrors,
		formState: { errors, isDirty, isSubmitting },
	} = methods

	const onSubmit: SubmitHandler<DocumentFormHandle> = data => {
		dispatch(
			saveLocationDocumentData({
				...data,
				location: selected,
				...(data.startDate && { startDate: momentZ(data.startDate).startOf("day").toISOString() }),
				...(data.endDate && { endDate: momentZ(data.endDate).endOf("day").toISOString() }),
			}),
		)
	}

	const handleClear = (e: React.MouseEvent) => {
		e.preventDefault()
		setDocumentName("")
		handleRow(null)
	}

	useEffect(() => {
		if (selected) reset(documentsInitialValues)
	}, [selected])

	useEffect(() => {
		if (documentURL) {
			setValue("link", documentURL, { shouldDirty: true, shouldValidate: true })
			dispatch(clearMedia())
		}
	}, [documentURL])

	useEffect(() => {
		document ? reset(document) : reset(documentsInitialValues)
	}, [document])

	return (
		<FormProvider {...methods}>
			<form className="p-2" onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-1/2 grid-cols-2 gap-x-6 gap-y-4">
					<div className="col-span-2 w-1/2">
						<FormSelectAntd
							disabled={!locationDocumentOptions.length}
							value={"_id" in (document || {}) ? `${document?.type}` : watch("type")}
							label="Type"
							name="type"
							options={locationDocumentOptions ?? []}
						/>
					</div>
					{(document?.type === LocationDocumentTypes.ASSIGNMENT_INSTRUCTIONS ||
						watch("type") === LocationDocumentTypes.ASSIGNMENT_INSTRUCTIONS) && (
						<>
							<DateField
								label="Start Date"
								name="startDate"
								errors={errors}
								clearErrors={clearErrors}
								setValue={setValue}
								watch={watch}
							/>
							<DateField
								label="End Date"
								name="endDate"
								errors={errors}
								clearErrors={clearErrors}
								setValue={setValue}
								watch={watch}
							/>
						</>
					)}
					<FormInputAntd className="mr-5.5 mt-1.25" label="Title" type="text" name="title" />
					<FormInputAntd label="Description" name="description" type="textarea" />
				</div>
				<div className="py-4">
					<DocumentField
						documentName={documentName}
						loading={isDocumentUrlUploading}
						onDocumentChange={file => {
							dispatch(uploadMedia(file))
							setDocumentName(file.name)
						}}
						name="link"
						errors={errors}
					/>
				</div>
				<div className="space-x-3">
					<AntdButton
						loading={isSaving}
						disabled={!isDirty || isSubmitting || isDocumentUrlUploading}
						htmlType="submit"
						className=""
					>
						{document ? "Update" : "Save"}
					</AntdButton>
					<AntdButton onClick={handleClear}>Clear</AntdButton>
				</div>
			</form>
		</FormProvider>
	)
}

export default Form
