import { Permissions } from "config/constants"

enum AppRoutes {
	SCHEDULES_PAGE = "/schedules",
	EMPLOYEES_PAGE = "/employees",
	ADMIN_PAGE = "/admin",
	REPORTS = "/reports",
	LOGIN_PAGE = "/auth/login",
	LOCATION = "location",
	EMPLOYEES = "employees",
	SCHEDULES = "schedules",
	WORK_MONITOR = "command-center",
	SECURITY = "security",
	ORGANIZATION = "organization",
	PAY_BILL = "pay-bill",
	STATIC_DATA = "static-data",
	JAF = "jaf",
	DASHBOARD = "analytics",
	ACCOUNTS = "accounts",
}

export const RouteToPermissions = {
	["/admin" as string]: Permissions.VIEW_ADMINISTRATION,
	["/reports" as string]: Permissions.VIEW_REPORTS,
	["/schedules/accounts" as string]: Permissions.VIEW_PAYROLL,
}

export default AppRoutes
